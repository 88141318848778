/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Link from '@material-ui/core/Link';
import tvlogo from './../images/tvicon.png';
import { Grid, IconButton } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import AuthApi from '../services/authApi';
import { useHistory } from "react-router-dom";

function Subcription() {
  const [sourceDb, setSourceDb] = useState("-");
  const [customerNumber, setCustomerNumber] = useState("-");
  const [customerId, setCustomerId] = useState("-");
  const [ownerId, setOwnerId] = useState('-');
  const [ownerCompanyName, setCompanyName] = useState('-');
  const [customerName, setCustomerName] = useState('-');
  const [traknpayApiKey, setTraknpayApiKey] = useState('-');
  const [nativeUpi, setNativeUpi] = useState('-');
  const history = useHistory();
  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDetails = async () => {
    let userData = {
      "records": [
        { "phone": localStorage.getItem("phoneNumber") }
      ]
    };
    await AuthApi.getdetail(userData).then((response) => {
      console.log(response);
      let data = response.data.customer_Details;
      setSourceDb(data.source_db);
      setCustomerNumber(data.customer_number);
      setCustomerId(data.stb_id);
      setOwnerId(data.owner_id);
      setCompanyName(data.owner_company_name);
      setCustomerName(data.customer_name);
      setTraknpayApiKey(data.owner_meta_value.traknpay_api_key);
      setNativeUpi(data.native_upi);

      localStorage.setItem("sourceDb", data.source_db);
      localStorage.setItem("customerNumber", data.customer_number);
      localStorage.setItem("customerId", data.customer_id);
      localStorage.setItem("ownerId", data.owner_id);
      localStorage.setItem("ownerCompanyName", data.owner_company_name);
      localStorage.setItem("customerName", data.customer_name);
      localStorage.setItem("traknpayApiKey", data.owner_meta_value.traknpay_api_key);
      localStorage.setItem("nativeUpi", data.native_upi);

      var jsonParse = JSON.parse(data.owner_meta_value);
      var getTraknpayApi = jsonParse.traknpay_api_key;
      var getTraknpaySalt = jsonParse.traknpay_salt_key;

      localStorage.setItem("traknpayApiKey", getTraknpayApi);
      localStorage.setItem("traknpaySaltKey", getTraknpaySalt);



    }).catch((e) => {
      alert("error in getting details");
      console.log(e);
    });
  }

  const getnextpage = () => {
    
    history.push('/subDetail')
  }
  return (

    <React.Fragment>
      <div className="row">
        <Grid container justify="flex-end" alignItems="flex-end">
          <IconButton deleteIcon3 size='large' style={{ padding: '3px', paddingRight: '15px', color: '#ff8c00' }} >
            <Help HelpIcon />
          </IconButton>
        </Grid>
               &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <img alt="Tv icon" src={tvlogo} wwidth="140" height="140" />

        <div style={{ paddingTop: '25px', paddingLeft: '45px' }}>
          <h4><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg>{customerName}</h4>
          <h5><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-inbox-fill" viewBox="0 0 16 16">
  <path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm-1.17-.437A1.5 1.5 0 0 1 4.98 3h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z"/>
</svg> {customerId}</h5>
          <h5><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-tv" viewBox="0 0 16 16">
  <path d="M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zM13.991 3l.024.001a1.46 1.46 0 0 1 .538.143.757.757 0 0 1 .302.254c.067.1.145.277.145.602v5.991l-.001.024a1.464 1.464 0 0 1-.143.538.758.758 0 0 1-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.464 1.464 0 0 1-.538-.143.758.758 0 0 1-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.46 1.46 0 0 1 .143-.538.758.758 0 0 1 .254-.302C1.498 3.078 1.675 3 2 3h11.991zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z"/>
</svg> {ownerCompanyName}</h5>
        </div>
      </div>
      <div>
        {/* <hr></hr>
        <center>  <h4 style={{ color: "#ff8c00", paddingLeft: '5px', paddingTop: '5px' }}>Your Subcription</h4></center> */}
      </div>
      <div><hr></hr>
      &nbsp;
        <center>
          <h4 style={{color:'#456383'}}>Click on the Pay Now to view the Bill & Detail.</h4></center> </div>

        &nbsp;
      <center>
        <button style={{color:'white', backgroundColor: '#ff8c00', width: '200px' }} onClick={() => getnextpage()}
          type="button" className="btn btn-lg btn-block">Pay Now</button></center>
                 &nbsp;
      <p></p>

    </React.Fragment>
  );
}

export default Subcription;