import React from "react";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Link from "@material-ui/core/Link";
import AuthApi from "../services/authApi";
import "./addComplaint.css";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    background: "white",
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 280,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 280,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function AddComplaint() {
  const classes = useStyles();
  const history = useHistory();
  const [Category, setCategory] = React.useState("");
  const [Complaint, setComplaint] = React.useState("");
  const [Remark, setRemark] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [ram, setRam] = React.useState(0);

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const handleChange1 = (event) => {
    setComplaint(event.target.value);
  };

  const handleChange2 = (event) => {
    setRemark(event.target.value);
  };

  const random = () => {
    const min = 1;
    const max = 100000;
    const rand = Math.floor(min + Math.random() * (max - min));
    console.log(rand);
    setRam(rand);
  };
  const onSignIn = () => {
    let isValid = true;
    if (Category === "" || Complaint === "") {
      setErrors({
        Category: <font color="red">Category can't be empty</font>,
        Complaint: <font color="red">Complaint can't be empty</font>,
        Remark: <font color="red">Remark can't be empty</font>,
      });
      isValid = false;
    }
    if (!isValid) {
      return;
    }

    let userData = {
      records: [
        {
          complaint_summary: {
            customer_number: localStorage.getItem("customerNumber"),
            customer_id: localStorage.getItem("customerId"),
            complaint_title_id: ram,
            complaint_title: Complaint,
            complaint_description: Remark,
            complaint_type: "SRVC",
            complaint_status: "REGISTERED",
            logged_by: localStorage.getItem("ownerId"),
            owner_id: localStorage.getItem("ownerId"),
            active: "Y",
            dmlType: "I",
            recordType: "N",
            created_by: "ONLN",
            created_by_id: localStorage.getItem("ownerId"),
            // this Is not required
          },
        },
      ],
    };
    console.log(userData);
    if (localStorage.getItem("sourceDb") === "rupayee") {
      random();
      AuthApi.addComplaintsrupee(userData)
        .then((response) => {
          console.log(response);
          if (response.data.p_out_mssg_flg === "S") {
            toast.success("Complaint added successfully", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            //history.push('/dashboard');
            // history.push('/mdashboard')
            localStorage.getItem("nxt")
              ? history.push("/nxtdashboard")
              : history.push("/dashboard");
          } else {
            //history.push('/unauthorized');
            alert("Complaint is already Exist");
            setRam(0);
          }
        })
        .catch((e) => {
          // history.push('/unauthorized');
          alert("Internet issue");
          console.log(e);
        });
    } else {
      AuthApi.addComplaints(userData)
        .then((response) => {
          console.log(response);
          if (response.data.p_out_mssg_flg === "S") {
            toast.success("Complaint added successfully", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
            history.push("/dashboard");
            // history.push('/mdashboard')
          } else {
            //history.push('/unauthorized');
            alert("Complaint is already Exist");
          }
        })
        .catch((e) => {
          // history.push('/unauthorized');
          alert("Internet issue");
          console.log(e);
        });
    }
  };
  const lol = () => {
    localStorage.getItem("nxt")
      ? history.push("/nxtdashboard")
      : history.push("/dashboard");
  };
  // const history1 = useHistory();
  return (
    <div
      style={{ backgroundColor: "#f5f5f5", height: "100%" }}
      className={classes.root}
    >
      <h3
        style={{
          color: "white",
          background: "#ff8c00",
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        <IconButton
          style={{ paddingRight: "25px" }}
          color="inherit"
          onClick={() => lol()}
        >
          <ArrowBackIcon />
        </IconButton>
        Add Complaint
      </h3>
      <br></br>
      <div className="inner">
        <form noValidate autoComplete="off">
          <h4
            style={{ color: "#ff8c00", paddingLeft: "5px", paddingTop: "5px" }}
          >
            Add your complaint here :<hr></hr>
          </h4>
          <span style={{ paddingLeft: "10px", fontWeight: "600" }}>
            Category :
          </span>{" "}
          <center>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Choose Catogery
              </InputLabel>
              <Select
                labelId="Category"
                id="Category"
                name="Category"
                value={Category}
                onChange={handleChange}
                label="Select Category"
              >
                <MenuItem value={"Payment"}>Payment</MenuItem>
                <MenuItem value={"Service"}>Service</MenuItem>
              </Select>
            </FormControl>
            <div classname="text-danger">{errors.Category}</div>
          </center>
          <span style={{ paddingLeft: "10px", fontWeight: "600" }}>
            Complaint Type :
          </span>
          <center>
            {" "}
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Choose Complaint Type
              </InputLabel>
              <Select
                labelId="Complaint"
                id="Complaint"
                name="Complaint"
                value={Complaint}
                onChange={handleChange1}
                label="Select Complaint Type"
              >
                <MenuItem value={"Issue with my payments"}>
                  Issue with my payments
                </MenuItem>
                <MenuItem value={"Not able to watch specific channel"}>
                  Not able to watch specific channel
                </MenuItem>
                <MenuItem value={"IVR problem"}>IVR problem</MenuItem>

                <MenuItem value={"Issue with receiving any signal"}>
                  Issue with receiving any signal
                </MenuItem>
                <MenuItem value={"Remote control issue"}>
                  Remote control issue
                </MenuItem>
                <MenuItem value={"Some channels are not available"}>
                  Some channels are not available
                </MenuItem>
              </Select>
            </FormControl>
            <div classname="text-danger">{errors.Complaint}</div>
          </center>
          <span style={{ paddingLeft: "10px", fontWeight: "600" }}>
            Remark :
          </span>
          <center>
            {" "}
            <TextField
              id="Remark"
              name="Remark"
              label="Remark"
              variant="outlined"
              multiline={true}
              onChange={handleChange2}
            />
          </center>
          <center>
            <hr></hr>{" "}
            <Link onClick={() => onSignIn()}>
              <button
                style={{
                  backgroundColor: "#ff8c00",
                  width: "200px",
                  color: "white",
                }}
                type="button"
                className="btn btn-lg btn-block"
              >
                Submit
              </button>
            </Link>{" "}
          </center>
          &nbsp;
        </form>
      </div>
    </div>
  );
}
