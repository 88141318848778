import React from "react";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Link from "@material-ui/core/Link";
import AuthApi from "../services/authApi";
import "../subcriptionDetail/addComplaint.css";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { toast } from "react-toastify";

import Loader from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";

import swal from "sweetalert";
toast.configure();

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    background: "white",
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 280,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 280,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Nxt_pay() {
  const classes = useStyles();
  const history = useHistory();
  const [amount_st, setamount_st] = React.useState("");
  const [errors, setErrors] = React.useState("");
  const [ram, setRam] = React.useState(0);

  var [isButtonLoading, setButtonLoading] = React.useState(false);

  const handleChange2 = (event) => {
    setamount_st(event.target.value);
  };

  const random = () => {
    const min = 1;
    const max = 100000;
    const rand = Math.floor(min + Math.random() * (max - min));
    console.log(rand);
    setRam(rand);
  };

  const onSignIn1 = () => {
    let tran =
      "SPTNXTHSUBWEB" + parseInt(localStorage.getItem("ownerId")) + Date.now();
    let data = JSON.stringify({
      AMOUNT: amount_st,
      PHONE: localStorage.getItem("phoneNumber"),
      OPERATOR_ID: parseInt(localStorage.getItem("ownerId")),
      flag: "E",
      CUST_NUM: parseInt(localStorage.getItem("payment_cust_num")),
      CUST_NAME: localStorage.getItem("payment_cust_name"),
      tran_id: tran,
    });
    console.log(data);
  };
  const onSignIn = async () => {
    //  alert(localStorage.getItem("ownerId"));
    let isValid = true;
    setButtonLoading(true);
    if (
      amount_st === "" ||
      localStorage.getItem("ownerId") === null ||
      localStorage.getItem("payment_cust_num") === null
    ) {
      if (amount_st === "") {
        setErrors("Please Enter a Amount");
        isValid = false;
      }
      if (localStorage.getItem("ownerId") === null) {
        setErrors("Something went wrong try Again....");
        isValid = false;
      }
      if (localStorage.getItem("payment_cust_num") === null) {
        isValid = false;
      }
    }
    if (!isValid) {
      setButtonLoading(false);
      return;
    } else {
      let tran =
        "SPTNXTHSUBWEB" +
        parseInt(localStorage.getItem("ownerId")) +
        Date.now();
      let data = JSON.stringify({
        AMOUNT: amount_st,
        PHONE: localStorage.getItem("phoneNumber"),
        OPERATOR_ID: localStorage.getItem("ownerId"),
        flag: "E",
        CUST_NUM: localStorage.getItem("payment_cust_num"),
        CUST_NAME: localStorage.getItem("payment_cust_name"),
        tran_id: tran,
      });
      console.log(data);
      await AuthApi.cashfree_init(data)
        .then((response) => {
          if (response.data.p_out_mssg_flg === "S") {
            window.location.href =
              "https://apps.mobiezy.in/CASHFREE/HOTEL/RAJIT/cashfree_ui.php?paymentSessionId=" +
              response.data.payment_session_id;

            //  window.location.href = "https://mobicollector.com/cashfree/checkout.php?PHONE=" + localStorage.getItem("phoneNumber")+ "&amount=" + amount_st+ "&name=" + "NA"+"&op_id="+parseInt(localStorage.getItem("ownerId"))+"&tran_id="+tran;
          } else {
            setButtonLoading(false);
            swal("Error", response.data.p_out_mssg, "warning");
          }
        })
        .catch((e) => {
          console.log(e);
        });
      // window.location.href = "https://mobicollector.com/cashfree/checkout.php?PHONE=9989839318&amount=1&name=Test&op_id=8488&tran_id=TRNNXTSUB84881651049226249"
    }
  };
  const lol = () => {
    localStorage.getItem("nxt")
      ? history.push("/nxtdashboard")
      : history.push("/dashboard");
  };
  // const history1 = useHistory();
  return (
    <div
      style={{ backgroundColor: "#f5f5f5", height: "100%" }}
      className={classes.root}
    >
      <h3
        style={{
          color: "white",
          background: "#ff8c00",
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        <IconButton
          style={{ paddingRight: "25px" }}
          color="inherit"
          onClick={() => lol()}
        >
          <ArrowBackIcon />
        </IconButton>
        Top-up Reacharge
      </h3>
      <br></br>
      <div className="inner">
        <form noValidate autoComplete="off">
          <h4
            style={{ color: "#ff8c00", paddingLeft: "5px", paddingTop: "5px" }}
          >
            <center> Pay Now</center>
            <hr></hr>
          </h4>

          <span style={{ paddingLeft: "10px", fontWeight: "600" }}>
            Enter Amount :
          </span>
          <center>
            {" "}
            <TextField
              id="amount"
              name="amount"
              label="Amount"
              variant="outlined"
              multiline={true}
              onChange={handleChange2}
            />
          </center>
          {isButtonLoading === true ? (
            <div className="btn btn-lg btn-block">
              {" "}
              <Loader
                type="Puff"
                bgColor={"#FFA500"}
                color={"#FFA500"}
                size={80}
              />
            </div>
          ) : (
            <center>
              <div classname="text-danger" style={{ color: "red" }}>
                {errors}
              </div>
              <hr></hr>{" "}
              <Link onClick={() => onSignIn()}>
                <button
                  style={{
                    backgroundColor: "#ff8c00",
                    width: "200px",
                    color: "white",
                  }}
                  type="button"
                  className="btn btn-lg btn-block"
                >
                  Submit
                </button>
              </Link>{" "}
            </center>
          )}
        </form>
      </div>
    </div>
  );
}
