/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Link from "@material-ui/core/Link";
import tvlogo from "./../images/tvicon.png";
import { Grid, IconButton } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import AuthApi1 from "../services/authApi";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

function NxttotalSTB() {
  const [sourceDb, setSourceDb] = useState("-");
  const [customerNumber, setCustomerNumber] = useState("-");
  const [customerId, setCustomerId] = useState("-");
  const [ownerId, setOwnerId] = useState("-");
  const [ownerCompanyName, setCompanyName] = useState("-");
  const [customerName, setCustomerName] = useState("-");
  const [custendDate,setCustEndDate]=useState("-");
  const [traknpayApiKey, setTraknpayApiKey] = useState("-");
  const [nativeUpi, setNativeUpi] = useState("-");
  const [stb_number,setstb_number]=useState("");
  const history = useHistory();
  useEffect(() => {
    console.log("API");
    localStorage.getItem("ismultiplestb")
      ? getmultiplestdDetails()
      : getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDetails = async () => {
    let userData = {
      records: [{ phone: localStorage.getItem("phoneNumber"), customer_number: localStorage.getItem("payment_cust_num") }],
    };
    console.log(JSON.stringify(userData));
    await AuthApi1.getdetail(userData)
      .then((response) => {
        console.log(response)
          let data = response.data.customerInfo;
     
        setSourceDb(data.source_db);
        setCustomerNumber(data.customer_number);
        setCustomerId(data.customer_id);
        setOwnerId(data.owner_id);
        setCustEndDate(data.PRE_END_DATE);
        setCompanyName(data.owner_company_name);
        setCustomerName(data.customer_name);
     //   setTraknpayApiKey(data.owner_meta_value.traknpay_api_key);
        setNativeUpi(data.native_upi);
        setstb_number(data.stb_number);

        localStorage.setItem("sourceDb", data.source_db);
        localStorage.setItem("customerNumber", data.customer_number);
        localStorage.setItem("customerId", data.customer_id);
        localStorage.setItem("ownerId", data.owner_id);
        localStorage.setItem("ownerCompanyName", data.owner_company_name);
        localStorage.setItem("customerName", data.customer_name);
        localStorage.setItem("ENDDATE",data.PRE_END_DATE);
        localStorage.setItem("sms_cust_id",data.SMS_CUST_ID);

       /* localStorage.setItem(
          "traknpayApiKey",
          data.owner_meta_value.traknpay_api_key
        );
        localStorage.setItem("nativeUpi", data.native_upi);*/

     /*   var jsonParse = JSON.parse(data.owner_meta_value);
        var getTraknpayApi = jsonParse.traknpay_api_key;
        var getTraknpaySalt = jsonParse.traknpay_salt_key;

        localStorage.setItem("traknpayApiKey", getTraknpayApi);
        localStorage.setItem("traknpaySaltKey", getTraknpaySalt);*/
      })
      .catch(function (error) {
        console.log(error);
        swal("Oops", "Something Went wrong", "error").then((value) => {
          history.push("/nxtdashboard");
        });
      });
  };

  const getmultiplestdDetails = async () => {
    let userData = {
      records: [
        {
          customer_number: localStorage.getItem("customerNumber"),
          owner_id: localStorage.getItem("ownerId"),
          phone: localStorage.getItem("phoneNumber"),
        },
      ],
    };
    console.log(JSON.stringify(userData));
  
    await AuthApi1.getmultipledetail(userData)
      .then((response) => {
        console.log('Multiple Response');
       console.log(response);
        let data = response.data.customerInfo;
        setSourceDb(data.source_db);
        setCustomerNumber(data.customer_number);
        setCustomerId(data.customer_id);
        setOwnerId(data.owner_id);
        setCompanyName(data.owner_company_name);
        setCustomerName(data.customer_name);
       // setTraknpayApiKey(data.owner_meta_value.traknpay_api_key);
        setNativeUpi(data.native_upi);
        setstb_number(data.stb_number);

        localStorage.setItem("sourceDb", data.source_db);
        localStorage.setItem("customerNumber", data.customer_number);
        localStorage.setItem("customerId", data.customer_id);
        localStorage.setItem("ownerId", data.owner_id);
        localStorage.setItem("ownerCompanyName", data.owner_company_name);
        localStorage.setItem("customerName", data.customer_name);
        
        localStorage.setItem("sms_cust_id",data.SMS_CUST_ID); 
        localStorage.setItem("traknpayApiKey", data.source_db);
        localStorage.setItem("traknpaySaltKey", data.source_db);
      })
      .catch(function (error) {
         console.log(error);
        swal("Oops", "Something Went wrong1", "error").then((value) => {
          history.push("/nxtdashboard");
        });
      });
  };
  const getnextpage = () => {
    localStorage.setItem("ec", "directpayment");
    history.push("/subDetail");
  };

  const getnextpagenxt = () => {
    history.push("/nxtrenewalchannel");
  };
  return (
    <React.Fragment>
      <div className="row">
        <Grid container justify="flex-end" alignItems="flex-end">
          <IconButton
            deleteIcon3
            size="large"
            style={{ padding: "3px", paddingRight: "15px", color: "#ff8c00" }}
          >
            <Help HelpIcon />
          </IconButton>
        </Grid>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <img alt="Tv icon" src={tvlogo} wwidth="140" height="140" />
        <div style={{ paddingTop: "25px", paddingLeft: "45px" }}>
          <h4>{customerName}</h4>
          <h5>{customerId}</h5>
          <h5>{stb_number}</h5>
         
        </div>
      </div>
      <div>
        {/* <hr></hr>
        <center>  <h4 style={{ color: "#ff8c00", paddingLeft: '5px', paddingTop: '5px' }}>Your Subcription</h4></center> */}
      </div>
      <div>
        <hr></hr>
        &nbsp;
      </div>
     
      <center>
        <button
          style={{ color: "white", backgroundColor: "#ff8c00", width: "200px" }}
          onClick={() => getnextpagenxt()}
          type="button"
          className="btn btn-lg btn-block"
        >
          Renew
        </button>
      </center>
      &nbsp;
      <p></p>
    </React.Fragment>
  );
}

export default NxttotalSTB;
