import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./backimg.css";

import moment from "moment";
import AuthApi from "../services/authApi";
import logo from "./../images/mpayicon2.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function Nxtotp() {
  const [hotp, setOtp] = useState("");
  const [storeotp, otpText] = useState("");
  const history = useHistory();

  const handleOtp = (e) => {
    otpText(e.target.value);
  };
  var phoneNumber = localStorage.getItem("Password");
  
  useEffect(() => {
    
   // getOtp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlekeydown=(event)=>{
    if(event.key==="Enter")
    {
      authenticateOtp();
    }
  }
  const getOtp = async () => {
    let userData = { phone_number: phoneNumber };
    await AuthApi.fetchOtp(userData)
      .then((response) => {
        console.log(response);
        setOtp(response.data.otpCode);
      })
      .catch((e) => {
        alert("error in generating otp");
        console.log(e);
      });
  };

  const authenticateOtp = async () => {
    if (phoneNumber === storeotp) {
      toast.success("Successfully Logged in to Mpay", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      localStorage.setItem("access token", "1");
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("nxt", true);
      var datef = moment();
      localStorage.setItem("currentdate", datef);
      //localStorage.getItem('currentm', true);

      var config = {
        records: [
          {
            phone: localStorage.getItem("phoneNumber"),
          },
        ],
      };

      AuthApi.getmpayownerlist(config).then((response) => {
        console.log(response);
        console.log("4230");
        let x = response.data.owner_details;
        localStorage.setItem("isLoggedIn", true);
       if (x.length > 1) {
          history.push("/nxt");
        } else {
          history.push("/nxtdashboard");
        }
      });
      //history.push("/dashboard");
     
    } else {
      alert("Oops, Enter valid Password");
    }
  };

  return (
    <div className="outer">
      <div className="inner">
        <div className="body">
          <form>
            <center>
              <img src={logo} alt="logo" width="100" height="50" />
            </center>
            <span className="larger">
              Mobi<span className="spcolor">Online</span> Payment
            </span>
            <hr></hr>
            <div className="form-group">
              <label className="medium">Verify Password :</label>
              <h6 className="txt">
                Step 1 : Enter 4 digit Password given by your LCO.
                click on Validate button.
              </h6>
              <h6 className="txt">
                Step 2: Incase of failure/error - Refresh the page and follow
                from step 1.
              </h6>
              &nbsp;
              <input
                style={{ borderRadius: "15px" }}
                type="tel"
                id="storeotp"
                onChange={handleOtp}
                className="form-control"
                pattern="[0-9]{4}"
                maxlength="4"
                placeholder="Enter 4 Digit Password"
                required
                onKeyDown={handlekeydown}
              />
            </div>
            &nbsp;
            <button
              style={{ backgroundColor: "#ff8c00" }}
              type="button"
              className="btn btn-lg btn-block"
              onClick={() => authenticateOtp()}
            >
              Validate
            </button>
            &nbsp;
            {/* <p className="forgot-password text-right">
                    Forgot <a href="#">password?</a>
                </p> */}
          </form>
          {/* <center><p>Made with <img src={logo1} alt="logo" width="20" height="20" /> in India!</p></center> */}
        </div>
      </div>
    </div>
  );
}

export default Nxtotp;
