import axios from "axios";

let authApi = axios.create({
  baseURL: "https://91z8byyrzi.execute-api.ap-south-1.amazonaws.com",
  headers: {
    "x-api-key": "aMqwyQJdA1aqH5GkpG5NR78UyswcHhkEaMZCfrC8",
    "Content-Type": "application/json",
  },
});
let authApi1 = axios.create({
  baseURL: "https://ld3igodwbj.execute-api.us-west-2.amazonaws.com/",
  headers: {
    "Content-Type": "application/json",
  },
});

let authApi2 = axios.create({
  baseURL: "https://c2.mobiezy.in/",
  headers: {
    "Content-Type": "application/json",
  },
});

let authApi3 = axios.create({
  baseURL: "https://zr36l28jaf.execute-api.ap-south-1.amazonaws.com",
  headers: {
    "x-api-key": "ffWim00xLx52kC5AI8xJk5B1tSBrRnyH3TqOv8Y5",
    "Content-Type": "application/json",
  },
});
// eslint-disable-next-line import/no-anonymous-default-export
//
export default {
  login(value) {
    return authApi3.post("/prod/mpay_v3_getphonenumberslist_prc", value);
  },
  fetchOtp(value) {
    return authApi.post("/prod/generateotpforverification", value);
  },
  getdetail(value) {
    return authApi1.post("/prod/getioniccustomerdetails_react_prc", value);
  },

  getmultipledetail(value) {
    return authApi1.post("/prod/getioniccustomerdetails_react_prc", value);
  },
  getRupayeePaymentDetails(value) {
    return authApi.post("/prod/mobiezyb2c-rupayee-getlatestbillmpay", value);
  },
  getMobicablePaymentDetails(value) {
    return authApi.post("/prod/mobiezyb2c-mobicable-getlatestbillmpay", value);
  },
  getTraknpayUrlRequest(value) {
    return authApi.post(
      "https://91z8byyrzi.execute-api.ap-south-1.amazonaws.com/prod/mobiezyb2c-tracknpaypaymentrequest",
      value
    );
  }, //
  getb2cSync(value) {
    return authApi1.post(
      "https://c2.mobiezy.in/api/b2c/pack_syncV2.php",
      value
    );
  },
  getBulkSync(value) {
    return authApi1.post(
      "https://c2.mobiezy.in/api/react/get_cust_pack_nxtV2.php",
      value
    );
  },
  NXTBulk_Activation(value) {
    return authApi1.post(
      "https://c2.mobiezy.in/api/react/b2c_nxt_event_with_update_hotel.php",
      value
    );
  },
  /* NXTBulk_Renewal(value) {
    return authApi1.post("prod/nxt_bulk_renewal_data_prc", value);
  },*/
  NXTBulk_Renewal(value) {
    return authApi1.post("prod/nxt_hotel_bulk_renewal_data", value);
  },
  NXTBulk_Change_Pack(value) {
    return authApi1.post("prod/change_pack_bulk_renewal_request", value);
  },
  //
  NXTActivation(value) {
    return authApi1.post("https://c2.mobiezy.in/api/b2c/nxt_modify.php", value);
  },
  NXTActivation_NEW(value) {
    return authApi2.post("https://c2.mobiezy.in/api/b2c/nxt_modify.php", value);
  },
  cancelRefund(value) {
    return authApi1.post("prod/sub_lco_cancel_request", value);
  },
  get_sub_lco_report(value) {
    return authApi1.post("prod/sub_lco_get_wallet_report", value);
  },
  getsubscriptiondetailsExpiry(value) {
    return authApi1.post("prod/get-subscription-expiry", value);
  },
  manageRenew(value) {
    return authApi1.post("/prod/mpayv3_web_renew", value);
  },
  getsubscriptiondetailsExpiryCustomer(value) {
    return authApi1.post("/prod/getprepaidexpirycustomers", value);
  },
  getlcowallet(value) {
    return authApi1.post(
      "https://c2.mobiezy.in/api/react/lco_balance_react.php",
      value
    );
  },
  sendTransactionDataToDb(value) {
    return authApi.post(
      "https://91z8byyrzi.execute-api.ap-south-1.amazonaws.com/prod/mobiezyb2c-addtotranlog",
      value
    );
  },
  cashfree_init(value) {
    return authApi.post(
      "https://c2.mobiezy.in/api/cashfree/generate_token_link_hotel.php",
      value
    );
  },
  getTranHistory(value) {
    return authApi.post("/prod/mobiezyb2c_rupayee_getCustomerPayment", value);
  },
  getTranHistory2(value) {
    return authApi.post("/prod/mobiezyb2c_getCustomerPaymentsList", value);
  },

  getComplaints(value) {
    return authApi.post("/prod/mpay-getcustomercomplaints", value);
  },

  addComplaintsrupee(value) {
    return authApi.post("/prod/mobiezyb2c_rupayee_manageComplaintsNew", value);
  },
  addComplaints(value) {
    return authApi.post("/prod/manageComplaint", value);
  },
  getsubscriptiondetailsnxt(value) {
    return authApi.post(
      "/prod/mobiezyb2c_rupayee_getSubscriptionDetails",
      value
    );
  },

  getnxtexistingpack(value) {
    return authApi.post("/prod/cableguy2-get-active-packs-test", value);
  },

  getmpayownerlist(value) {
    return authApi.post("/prod/mpayv2_getmpayownerlist", value);
  },

  getmpaycancelstbreport(value) {
    return authApi.post("/prod/MPayV2_CANCELLATION_STB_REPORT", value);
  },
};
