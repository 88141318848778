import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Change_pack from "./nxt/change_pack"
import Login from "./login&otp/login";
import OtpPage from "./login&otp/otpPage";
import Dashboard from './dashboard/Dashboard';
import PaymentHis from './payment/paymentHistory'
import Bulk_Renewal from './nxt/bulk_renewal'
import Nxt_pay from './nxt/nxt_pay'
import Notifications from './notification/allNotificaton'
import AddComplaint from './subcriptionDetail/addComplaint'
import SubDetail from './subcriptionDetail/subDetail'
import AllComplaint from './subcriptionDetail/allComplaint'
import CRequest from './nxt/cancel_request'
import Termsncondition from './termsncondition'
import Helpnsupport from './helpnsupport'
import Nxtotp from './login&otp/Nxtotp';
import Nxtdashboard from './dashboard/Nxtdashboard';
import Nxtrenewchannel from './dashboard/Nxtrenewchannel';
import Nxt_Renewal  from './nxt/NXT_Renewal';
import Pack_info from './nxt/pack_info';
import NXT_CANCEL_RENEWAL from './nxt/NXT_CANCEL_RENEWAL';
import CANCEL_STB_REPORT from './nxt/CANCEL_STB_REPORT';
import Nxtexistingpath from './dashboard/Nxtexistingpath';
import NXT_BULK_CANCEL from './nxt/NXT_BULK_CANCEL';
import Nxtexpiredpath from './dashboard/Nxtexpiredpath';
import Nxtrenewinner from './subcriptionDetail/Nxtrenewinner';
import Nxtmodifiedpackdetails from './subcriptionDetail/Nxtmodifiedpackdetails';
import Nxtaddchannel from './subcriptionDetail/Nxtaddchannel';
import Nxtaddchannelspackdetials from './subcriptionDetail/Nxtaddchannelspackdetials';
import Nxtmain from './nxt/Nxtmain';
import Success from './common/Success';
import Failure from './common/Failure';


function App() {
  return (<Router>

    <Switch>
      <Route exact path='/' component={Login} />
      <Route path="/login" component={Login} />
      <Route path="/nxtotp" component={Nxtotp} />
      <Route path="/otpPage" component={OtpPage} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/nxtdashboard" component={Nxtdashboard} />
      <Route path="/nxt" component={Nxtmain} />
      <Route path="/Nxt_Renewal" component={Nxt_Renewal}/>
      <Route path="/sucess" component={Success} />
      <Route path="/fail" component={Failure} />
      <Route path="/Nxtexistingpath" component={Nxtexistingpath} />
      <Route path="/Nxtexpiringpath" component={Nxtexpiredpath} />
      <Route path="/Nw" component={Nxtrenewinner} />
      <Route path="/change"component={Change_pack}/>
      <Route path="/na" component={Nxtaddchannel} />
      <Route path="/nxtrenewalchannel" component={Nxtrenewchannel} />
      <Route path="/paymentHistory" component={PaymentHis} />
      <Route path="/wallet_history" component={Bulk_Renewal}/>
      <Route path="/allNotificaton" component={Notifications} />
      <Route path="/addComplaint" component={AddComplaint} />
      <Route path="/subDetail" component={SubDetail} />
      <Route path="/allComplaint" component={AllComplaint} />
      <Route path="/termsncondition" component={Termsncondition} />
      <Route path="/helpnsupport" component={Helpnsupport} />
      <Route path="/nwr" component={Nxtmodifiedpackdetails} />
      <Route path="/Pack_info" component={Pack_info}/>
      <Route path="/nwa" component={Nxtaddchannelspackdetials} />
      <Route path="/CRequest" component={CRequest}/>
      <Route path="/NxtPay"component={Nxt_pay}/>
      
      <Route path="/RENEW"component={NXT_CANCEL_RENEWAL}/>
      <Route path="/CANCEL" component={NXT_BULK_CANCEL}/>
      <Route path="/CANCEL_STB_REPORT" component={CANCEL_STB_REPORT}/>

    </Switch>

  </Router>
  );
}

export default App;
