


import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import swal from "sweetalert"; 
import AuthApi1 from "../services/authApi";
import { makeStyles } from '@material-ui/core/styles';

import Loader from "react-loader-spinner";
import axios from 'axios';
import '../subcriptionDetail/addComplaint.css'
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
toast.configure()


const useStyles = makeStyles((theme) => ({
  root: {

    overflow: 'auto',
    background: 'white',
    height:'100vh',
    width: '100%',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 280,
    },

  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 280,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


export default function Nxtaddchannelspackdetials() {
    const classes = useStyles();
  const history = useHistory();
  const [a, setA] = useState([]);
    const [b, setB] = useState([]);
    const [c, setC] = useState([]);
    const [mso, setMso] = useState([]);
    const [pc, setPc] = useState([]);
    const [bft, setBft] = useState([]);
    
  const [pay_loader, set_pay_Loader] = useState(false);
    const [totalamount , setTotalamount] = useState([])
  useEffect(() => {
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getItems = () => {
    var config = {
      method: 'post',
      url: 'https://91z8byyrzi.execute-api.ap-south-1.amazonaws.com/prod/rupayee-getb2cpacklist-new',
     
      data : {"records":[{"operator_id":localStorage.getItem("ownerId")}]}
    };
    
    axios(config)
    .then(function (response) {
      setC(response.data.mso_bouquet);
      setB(response.data.pay_channel)
      setA(response.data.base_pack_fta)
      const obj = localStorage.getItem("naa")
      const obj1 = localStorage.getItem("nab")
      const obj2 = localStorage.getItem("nac")
      const myArr = obj.split(",")
      const myArr1 = obj1.split(",")
      const myArr2 = obj2.split(",")
      console.log(myArr1)
      console.log(myArr2)
      var x = response.data.mso_bouquet
      var doubled = myArr.map((number) => x.find(element => element.pack_name == number));
      console.log(doubled)
      setMso(doubled)
      var x1 = response.data.pay_channel
      var doubled1 = myArr1.map((number) => x1.find(element => element.pack_name == number));
      console.log(x1)
      setPc(doubled1)
    //   var x2 = response.data.base_pack_fta
    //   var doubled2 = myArr2.map((number) => x2.find(element => element.pack_name == number));
    //   console.log(doubled2)
      //setBft(doubled2)
     // console.log(doubled2)
      console.log(response.data.base_pack_fta)
      //console.log(doubled2)
      const comps = doubled.map((value) => parseFloat(value == undefined ? 0 : value.total_amount))

      console.log(comps)
        
      console.log(
          comps.reduce((a, b) => a + b, 0)
        )
        let xx = comps.reduce((a, b) => a + b, 0)

        const compss = doubled1.map((value) => parseFloat(value == undefined ? 0 : value.total_amount))

      console.log(compss)
        
      console.log(
          compss.reduce((a, b) => a + b, 0)
        )
        let xx1 = compss.reduce((a, b) => a + b, 0)

      //  const compses = doubled2.map((value) => parseFloat(value.total_amount))

     // console.log(compses)
        
    //  console.log(          compses.reduce((a, b) => a + b, 0)        )
       // let xx2 = compses.reduce((a, b) => a + b, 0)
        setTotalamount((xx == undefined ? 0 : xx) + (xx1 == undefined ? 0 : xx1) )
    })

    
    .catch(function (error) {
      console.log(error);
    });
  }
  const NXTActivate=async()=>{
    let data= JSON.stringify({
      lcousername: localStorage.getItem("lcocode"),
      stb_id:localStorage.getItem("stdid"),
      phone: localStorage.getItem("phoneNumber"),
      owner_id: parseInt(localStorage.getItem("ownerId")),
      agent_id:"1",
      cust_num:localStorage.getItem("customerNumber"),
      nxt_id:localStorage.getItem("sms_cust_id"),
    });
  
    var config = {
      method: 'post',
      url: 'http://c2.mobiezy.in/api/b2c/nxt_add_crm.php',
      data 
     
    };
    
    axios(config)
    .then(function (response) {
      set_pay_Loader(false);
      swal("Success", "Your Request has been Successfully Submitted", "success")
      history.push("/nxtdashboard")
      console.log(response);
    })
    .catch(function (error) {
      set_pay_Loader(false);
      console.log(error);
      swal("Oops", "Something Went wrong", "error")
    });
   

   
  }
  const nxtaddchannel = async () =>{
    set_pay_Loader(true);
    if(mso[0] == undefined){
      var x = [...pc]
    }else if(pc[0] == undefined){
      var x = [...mso]
    }else{
var x = [...mso,...pc]
    }
    localStorage.setItem("ec","addchannel")
    localStorage.setItem("paymentamount",totalamount)
    
    localStorage.setItem("active_pac",JSON.stringify(x))
   // console.log('pack');

    let data1={
      "stb_id":localStorage.getItem("stdid"),
      "pack_details":x,
      "cust_num":localStorage.getItem("customerNumber"),
      "agent_id":"1",
      "dml_type":"Add"
    };
    console.log(JSON.stringify(data1));
    await AuthApi1.manageRenew((data1))
    .then((response) => {
      console.log(response)
       if(response.data.p_out_mssg_flg==="S")
       {
        NXTActivate();
  
        
       }
       else
       {
        set_pay_Loader(false);
        swal(
          "Alert",
          `Something Went Wrong....`,
          "warning"
        );
         
       }
    
    })
  }
    return (
        <div style={{ background: 'white', height:'100vh'}}>
          <div style={{ backgroundColor: '#f5f5f5', height: "100%" }} className={classes.root}>
      <h3 style={{ color: 'white', background: '#ff8c00', paddingTop: '8px', paddingBottom: '8px' }}>
        <IconButton style={{ paddingRight: '25px' }} color="inherit" onClick={() => history.push('/na')}>
          <ArrowBackIcon />
        </IconButton>Add Channels</h3>
      <br></br>
      <center>
          Your Added Channels
          &nbsp;
      <div
        
      >




<table>
  <tr>
    <th style={{width:'30vw'}}>Pack Name</th>
    <th style={{width:'30vw'}}>Pack Type</th>
    <th style={{width:'30vw'}}>Price</th>
  </tr>
 
 
 {mso.map((value) =>
  <tr>
    <td>{value === undefined ? '' : value.pack_name}</td>
    <td>{value === undefined ? '' : value.pack_type}</td>
    <td> {value === undefined ? '' :"\u20B9"+ value.total_amount+"/-"}</td>
  </tr>
)}
 {pc.map((value) =>
 
  <tr>
    <td>{value === undefined ? '' : value.pack_name}</td>
    <td>{value === undefined ? '' : value.pack_type}</td>
    <td>{value === undefined ? '' : "\u20B9"+value.total_amount+'/-'} </td>
  </tr>
)}
</table>
{/* <div>
<Link href="/addComplaint" onClick={'/addComplaint'}>
  <Fab  aria-label="add" className={classes.margin}>
  
    <AddIcon />
  </Fab></Link>
</div> */}


{/* <Container>
  <Link href="/addComplaint">
    <Fab aria-label="add" tooltip="Add Complaint" className={classes.margin}>

      <AddIcon />
    </Fab></Link>
</Container> */}
</div>

<div style={{ marginTop:'20px' , marginBottom:'20px' , color: 'blue'}} >Total Amount (Incl GST)  : <span style={{ marginTop:'20px' , marginBottom:'20px' , color: 'red'}} > {"\u20B9"} {totalamount} /-</span></div>

&nbsp;

{pay_loader ? (
              <div>
                <center>
                  {" "}
                  <Loader
                    type="TailSpin"
                    color="#ff8c00"
                    height={100}
                    width={100}
                  />{" "}
                </center>
              </div>
            ) : (
              <button style={{color: "white", backgroundColor: '#ff8c00', width: '260px', textDecoration: 'none' }}
              type="button"  onClick={()=>nxtaddchannel()}className="btn btn-lg btn-block" >Add </button>
    
            )}
&nbsp;
</center>

    </div>   
        </div>
    )
}
