import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Complaint from "./../images/notification.png";
import Rupay from "./../images/rupees1.png";
import Avatar from "@material-ui/core/Avatar";
import { Container, Button } from "react-floating-action-button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Link from "@material-ui/core/Link";
import Loader from "react-loader-spinner";
import AuthApi from "../services/authApi";
import AuthApi1 from "../services/authApi";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: "auto",
    background: "white",
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: "#ff7700",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledAccordions() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [comp, setComp] = React.useState([]);
  const [expDate, setexpDate] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getItems = async () => {
    try {
      let data = JSON.stringify({
        L_FLAG: "REACT_CUSTOMER",
        sub_oid: parseInt(localStorage.getItem("payment_cust_num")),
      });
      console.log(data);
      await AuthApi1.get_sub_lco_report(data).then((response) => {
        console.log(response);
        setLoading(true);
        let plans = response.data.main_lco;
        let planslist = plans
          .sort((a, b) => a.No_of_days - b.No_of_days)
          .map((plan) => {
            return {
              No_of_days: plan.insertion_time,

              No_of_STB: plan.tran_details,
              user_name: plan.deduction,
              deposit: plan.deposit,
              balance: plan.balance,
            };
          });

        setexpDate(planslist);
      });
    } catch (e) {}
  };
  const getItems1 = async () => {
    try {
      if (localStorage.getItem("sourceDb") === "rupayee") {
        let userData = {
          records: [
            {
              owner_id: localStorage.getItem("ownerId"),
              customer_number: localStorage.getItem("customerNumber"),
              subscription_id: null,
              app: "react",
            },
          ],
        };
        console.log(userData);
        await AuthApi.getTranHistory(userData).then((response) => {
          console.log(response);
          if (response.data.customerPaymentsList !== undefined) {
            console.log(" successful");
            setLoading(true);
            let plans = response.data.customerPaymentsList;

            let planslist = plans.map((plan) => {
              return {
                payment_id: plan.payment_id,
                payment_date: plan.payment_date,
                // user_name:plan.user_name,
                payment_amount: plan.payment_amount,
                mode_of_payment: plan.mode_of_payment,
              };
            });

            setComp(planslist);
          } else {
            alert("There is No Transactions");
          }
        });
      } else {
        let userDatas = {
          records: [
            {
              owner_id: localStorage.getItem("ownerId"),
              customer_number: localStorage.getItem("customerNumber"),
              app: "react",
            },
          ],
        };
        console.log(userDatas);
        await AuthApi.getTranHistory2(userDatas).then((response) => {
          console.log(response);
          if (response.data.customerPaymentsList !== undefined) {
            console.log(" successful1");
            setLoading(true);
            let planss = response.data.customerPaymentsList;

            let planslists = planss.map((plan) => {
              return {
                payment_id: plan.payment_id,
                // user_name:plan.user_name,
                payment_date: plan.payment_date,
                payment_amount: plan.payment_amount,
                mode_of_payment: plan.mode_of_payment,
              };
            });

            setComp(planslists);
          } else {
            alert("There is No Transactions");
          }
        });
      }
    } catch (error) {}
  };

  const handleRowClick = (data) => {
    // localStorage.getItem('nxt') ? history.push("/nxtdashboard") : history.push("/dashboard")
    localStorage.setItem("data", data);
    history.push("/Nxt_Renewal");
  };

  const na = "---";
  const DisplayData = expDate.map((info) => {
    return (
      <tr>
        <td>{moment.utc(info.No_of_days).format("YYYY-MM-DD")}</td>
        <td>{info.No_of_STB}</td>
        <td>{info.user_name}</td>
        <td>{info.deposit}</td>
        <td>{info.balance}</td>
      </tr>
    );
  });

  const history = useHistory();

  const isLoggedIn = localStorage.getItem("isLoggedIn");
  console.log(isLoggedIn);
  if (isLoggedIn === null) {
    history.push("/login");
  }
  const lol = () => {
    // localStorage.getItem('nxt') ? history.push("/nxtdashboard") : history.push("/dashboard")
    history.push("/nxt"); // bulk_renewal
  };

  return (
    <div className={classes.root}>
      <h3
        style={{
          color: "white",
          background: "#ff8c00",
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        <IconButton
          style={{ paddingRight: "25px" }}
          color="inherit"
          onClick={() => lol()}
        >
          <ArrowBackIcon />
        </IconButton>
        Wallet History
      </h3>
      {/* <div>
     <Link href="/addComplaint" onClick={'/addComplaint'}>
        <Fab  aria-label="add" className={classes.margin}>
        
          <AddIcon />
        </Fab></Link>
      </div> */}

      {!loading ? (
        <div
          style={{
            width: "100%",
            height: "100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "200px",
          }}
        >
          <Loader type="Oval" color=" #ff8c00" height="70" width="70" />
        </div>
      ) : (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Date</th>
              <th>Transaction Details</th>
              <th>Deduction</th>
              <th>Deposit</th>
              <th>Wallet Balance</th>
            </tr>
          </thead>
          <tbody>{DisplayData}</tbody>
        </table>
      )}

      {/* <Container>
        <Link href="/addComplaint">
          <Fab aria-label="add" tooltip="Add Complaint" className={classes.margin}>

            <AddIcon />
          </Fab></Link>
      </Container> */}
    </div>
  );
}
