import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import Loader from "react-loader-spinner";
import Paper from "@material-ui/core/Paper";

import tvlogo from "./../images/tvicon.png";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PaymentIcon from "@material-ui/icons/AccountBalance";
import SyncIcon from "@material-ui/icons/Sync";
import TranHisIcon from "@material-ui/icons/AccountBalanceWallet";

// import AddComplaintIcon from '@material-ui/icons/AddComment';
import AllComplaintIcon from "@material-ui/icons/Forum";
import logo from "./../images/mpayicon2.png";
import Logout from "@material-ui/icons/AccountCircle";
import HelpIcon from "@material-ui/icons/Help";

import AuthApi from "../services/authApi";

import AuthApi1 from "../services/authApi";
import ReportIcon from "@material-ui/icons/Report";
// import Link from '@material-ui/core/Link';
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import logo1 from "./../images/heart1.png";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <h5>
        Made with <img src={logo1} alt="logo" width="25" height="25" /> in
        India!
      </h5>
    </Typography>
  );
}

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "#F5F5F5",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 220,
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    margin: theme.spacing(2),

    borderRadius: "10px",
    padding: "20px",
  },
  tvicon: {
    width: "35%",
    marginLeft: "10px",
  },
}));

export default function Nxtdashboard() {
  const history = useHistory();
  const classes = useStyles();
  const classes1 = useStyles1();
  const [open, setOpen] = React.useState(false);

  const [dashboarddata, setDashboarddata] = React.useState([]);
  const [lcowallet, setlcowallet] = React.useState("");
  const [loader, setLoader] = React.useState(false);

  const isLoggedIn = localStorage.getItem("isLoggedIn");
  console.log(isLoggedIn);
  if (isLoggedIn === null) {
    history.push("/login");
  }
  const res = localStorage.getItem("BULK_IMPORT_FLAG");
  /*  if (res === "Y") {
    useEffect(() => {
      getlcowallet();
      onbulksync();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  } else {
    useEffect(() => {
      getlcowallet();
      hello();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  }
*/
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getlcowallet();
        if (res === "Y") {
          onbulksync();
        } else {
          hello();
        }
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const hello = async () => {
    var config = {
      records: [
        {
          phone: localStorage.getItem("phoneNumber"),
          flag: "BR1",
        },
      ],
    };

    await AuthApi.getmpayownerlist(config)
      .then((response) => {
        console.log("data");
        console.log(response);
        setDashboarddata(response.data.owner_details);
        localStorage.setItem("BULK_IMPORT_FLAG", "N");
        // console.log("Bulk Sync Flag :",response.data.owner_details[0].BULK_IMPORT_FLAG);
        //  response.data.owner_details[0].BULK_IMPORT_FLAG === 'Y'? onbulksync() : ""
      })
      .catch((e) => {
        console.log("hello Error");

        console.log(e);
      });
  };
  const getlcowallet = async () => {
    let data = JSON.stringify({
      lcousername: localStorage.getItem("lcocode"),
      phone: localStorage.getItem("phoneNumber"),
      operator_id: parseInt(localStorage.getItem("ownerId")),
    });
    console.log(data);
    await AuthApi.getlcowallet(data)
      .then((response) => {
        console.log(response);
        setlcowallet(response.data);
      })
      .catch((e) => {
        console.log("Wallet Error");

        console.log(e);
      });
  };
  const getDetails = async () => {
    let data = JSON.stringify({
      lcousername: localStorage.getItem("lcocode"),
      phone: localStorage.getItem("phoneNumber"),
      owner_id: parseInt(localStorage.getItem("ownerId")),
      nxt_id: parseInt(localStorage.getItem("sms_cust_id")),
      cust_num: parseInt(localStorage.getItem("customerNumber")),
    });

    console.log(data);
    console.log("SYNC INPUT");

    await AuthApi.getb2cSync(data)
      .then((response) => {
        console.log("Sync Data111");
        console.log(response);
        console.log("Sync Data");
      })
      .catch((e) => {
        console.log("SYNC ERORR");

        console.log(e);
      });
  };

  const onClickbutton = (id, num) => {
    console.log("hello");
    localStorage.setItem("ownerId", id);
    localStorage.setItem("customerNumber", num);
    localStorage.setItem("ismultiplestb", true);
    console.log(num);
    history.push("/nxtdashboard");
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const one = () => {
    history.push("/nxt");
  };
  const two = () => {
    history.push("/CRequest");
  };
  const wallet = () => {
    history.push("/NxtPay");
  };
  const wallet_history = () => {
    history.push("/wallet_history");
  };
  const three = () => {
    history.push("/NXT_Renewal");
  };
  const three1 = () => {
    history.push("/RENEW");
  };
  const three2 = () => {
    history.push("/CANCEL");
  };
  const four = () => {
    history.push("/helpnsupport");
  };
  const five = () => {
    history.push("/termsncondition");
  };

  const cancel_stb = () => {
    history.push("/CANCEL_STB_REPORT");
  };
  const six = () => {
    history.push("/login");
    localStorage.clear();
    console.log(localStorage.getItem("phoneNumber"));
  };
  const onbulksync = async () => {
    setLoader(true);
    console.log("bulk sync");
    let data = JSON.stringify({
      lcousername: localStorage.getItem("lcocode"),
      PHONE: localStorage.getItem("phoneNumber"),
      operator_id: parseInt(localStorage.getItem("ownerId")),
    });
    console.log(data);
    await AuthApi1.getBulkSync(data)
      .then((response) => {
        console.log(response);
        toast("Refreshed Successfully");
        setLoader(false);
        hello();
        // setTimeout(() => {
        //   window.location.reload()
        // }, 1000);
      })
      .catch((e) => {
        console.log("Wallet Error");
        setLoader(false);
        console.log(e);
      });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={{ backgroundColor: "#ff8c00" }}
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>
          <IconButton color="inherit">
            <Badge badgeContent={0} color="secondary">
              <AccountBalanceWalletIcon /> Wallet Balance :{lcowallet}
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {" "}
          <ListSubheader inset>
            <img src={logo} alt="logo" width="110" height="45" />
          </ListSubheader>
          <hr></hr>
          <span>
            <ListItem button onClick={() => one()}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
          </span>
          <span>
            <ListItem button onClick={() => three()}>
              <ListItemIcon>
                <AllComplaintIcon />
              </ListItemIcon>
              <ListItemText primary="Bulk Renewal" />
            </ListItem>
          </span>
          {localStorage.getItem("cancellation_enabled") === "Y" ? (
            <span>
              <ListItem button onClick={() => three2()}>
                <ListItemIcon>
                  <AllComplaintIcon />
                </ListItemIcon>
                <ListItemText primary="Bulk Cancel" />
              </ListItem>
            </span>
          ) : (
            ""
          )}
          <span>
            <ListItem button onClick={() => three1()}>
              <ListItemIcon>
                <AllComplaintIcon />
              </ListItemIcon>
              <ListItemText primary="CANCEL STB  Renewal" />
            </ListItem>
          </span>
          <span>
            <ListItem button onClick={() => two()}>
              <ListItemIcon>
                <TranHisIcon />
              </ListItemIcon>
              <ListItemText primary="Cancellation Report" />
            </ListItem>
          </span>
          <span>
            <ListItem button onClick={() => wallet()}>
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary="Top-up Wallet" />
            </ListItem>
          </span>
          <span>
            <ListItem button onClick={() => wallet_history()}>
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary="Wallet History" />
            </ListItem>
          </span>
          <span>
            <ListItem button onClick={() => cancel_stb()}>
              <ListItemIcon>
                <TranHisIcon />
              </ListItemIcon>
              <ListItemText primary="Cancellation STB Report" />
            </ListItem>
          </span>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={() => four()}>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="Help & Support" />
          </ListItem>
          <ListItem button onClick={() => five()}>
            <ListItemIcon>
              <ReportIcon />
            </ListItemIcon>
            <ListItemText primary="Terms & Condition" />
          </ListItem>
          <ListItem button onClick={() => six()}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <div>
            <center>
              {" "}
              <h4>
                Your subscriptions are listed below .<br /> Click on any one
                subscription to view the bill
              </h4>
            </center>
            {loader ? (
              <div>
                <center>
                  {" "}
                  <Loader
                    type="TailSpin"
                    color="#ff8c00"
                    height={100}
                    width={100}
                  />{" "}
                </center>
              </div>
            ) : (
              <div style={{ float: "right" }}>
                <Button
                  onClick={() => onbulksync()}
                  variant="outlined"
                  startIcon={<SyncIcon />}
                >
                  Refresh
                </Button>
              </div>
            )}

            <Grid container id="GridRefresh">
              {dashboarddata.map((value) => (
                <Grid item xs="12" sm="6" md="4">
                  <Paper
                    className={classes1.paper}
                    onClick={() =>
                      onClickbutton(value.owner_id, value.customer_number)
                    }
                  >
                    <div className="row">
                      <div className={classes1.tvicon}>
                        <img
                          alt="Tv icon"
                          src={tvlogo}
                          width="100"
                          height="100"
                        />
                      </div>
                      <div style={{ width: "50%" }}>
                        <h5>
                          <b>{value.customer_name}</b>
                        </h5>
                        <h5>ID:{value.SMS_CUST_ID}</h5>
                        <h6 style={{ color: "black", fontSize: "1.15rm" }}>
                          EXPIRY DATE:{value.PRE_END_DATE}
                        </h6>
                        <h6 style={{ color: "black", fontSize: "1.0rm" }}>
                          PACK PRICE:{value.pack_sum}
                        </h6>
                        <h6 style={{ color: "black", fontSize: "1.0rm" }}>
                          BOX NO:{value.VC_NUMBER}
                        </h6>
                      </div>
                    </div>

                    {/* <div id="textbox"><hr></hr>
  <h5 className="alignleft">Month</h5>
  <h5 className="alignright">Feb-2021</h5>
  
</div> */}
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </div>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
