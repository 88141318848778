import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: "auto",
    background: "white",
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: "#ff7700",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledAccordions() {
  const classes = useStyles();

  const history = useHistory();
  const lol = () => {
    localStorage.getItem("nxt")
      ? history.push("/nxtdashboard")
      : history.push("/dashboard");
  };

  return (
    <div className={classes.root}>
      <h3
        style={{
          color: "white",
          background: "#ff8c00",
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        <IconButton
          style={{ paddingRight: "25px" }}
          color="inherit"
          onClick={() => lol()}
        >
          <ArrowBackIcon />
        </IconButton>
        Help and Support
      </h3>
      <h4
        style={{
          fontWeight: "bold",
          paddingLeft: "20px",
          paddingRight: "10px",
        }}
      >
        Money got deducted from bank account but not getting confirmation from
        vendor
      </h4>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        This has happened because post deduction the payment gateway or your
        bank did not give us success or failure confirmation or we are not able
        to get the correct status from the bank due to network issues. No
        worries, all incomplete transactions we will check again with them in
        regular intervals and give you the confirmation within a day.
      </h6>
      <hr></hr>

      <h4
        style={{
          fontWeight: "bold",
          paddingLeft: "20px",
          paddingRight: "10px",
        }}
      >
        Transaction failed but money got deducted
      </h4>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        This is due to post deduction the payment gateway or your bank did not
        give us success or failure confirmation or we are not able to get the
        correct status from the bank due to network issues. No worries, all
        incomplete transactions we will check again with them in regular
        intervals for next 48 hours and give you the confirmation within some
        time.
      </h6>
    </div>
  );
}
