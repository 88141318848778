import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
//import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
//import InputLabel from '@material-ui/core/InputLabel';
//import MenuItem from '@material-ui/core/MenuItem';
//import FormControl from '@material-ui/core/FormControl';
//import Select from '@material-ui/core/Select';
//import Link from '@material-ui/core/Link';
//import ReactDOM from 'react-dom'
//import MaterialTable from 'material-table'
//import AuthApi from '../services/authApi';
import axios from "axios";
import "../subcriptionDetail/addComplaint.css";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";
//import { Category } from '@material-ui/icons';
import Success from "../common/Success";
toast.configure();

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    background: "white",
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 280,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 280,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Nxtexpiredpath() {
  const classes = useStyles();
  const history = useHistory();
  const [Category, setCategory] = React.useState([]);
  const [Dates, setDates] = React.useState("");
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const nxtpage = () => {
    localStorage.setItem("ec", "b");
    localStorage.setItem("paymentamount", Dates);
    history.push("/subDetail");
  };
  const getDetails = async () => {
    let x = {
      customerId: localStorage.getItem("customerNumber"),
      operatorId: localStorage.getItem("ownerId"),
      stbId: localStorage.getItem("stdid"),
    };

    var config = {
      method: "post",
      url: "https://ld3igodwbj.execute-api.us-west-2.amazonaws.com/prod/cableguy2-get-active-packs-test",

      data: x,
    };

    axios(config).then(function (response) {
      console.log(response.data.active_pac);
      setCategory(response.data.active_pac);
      const comps = response.data.active_pac.map((value) =>
        parseFloat(value.packBasePrice)
      );
      //let active_pac = [response.data.active_pac]
      //export let active_pac = response.data.active_pac
      // localStorage.setItem("active_pac",active_pac)
      localStorage.setItem(
        "active_pac",
        JSON.stringify(response.data.active_pac)
      );

      console.log(comps);

      console.log(comps.reduce((a, b) => a + b, 0));
      let x = comps.reduce((a, b) => a + b, 0);
      let y = x == 0 ? "" : x;
      console.log("y" + y);
      let z = parseFloat(localStorage.getItem("stddue"));
      let zz = parseFloat(x.toFixed(2));
      localStorage.setItem("sta", z + zz);
      setDates((z + zz).toFixed(2));
      setLoader(false);
    });
  };

  const comp = Category.map((value) => (
    <tr>
      <td>{value.packName}</td>
      <td>{value.packType}</td>
      <td>
        {"\u20B9"} {value.packBasePrice}{" "}
      </td>
    </tr>
  ));

  return (
    <div>
      {loader ? (
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: "50%",
            bottom: 0,
            height: "100vh",
          }}
        >
          <center>
            {" "}
            <Loader
              type="TailSpin"
              color="#ff8c00"
              height={100}
              width={100}
            />{" "}
          </center>
        </div>
      ) : (
        <div
          style={{ backgroundColor: "#f5f5f5", height: "100%" }}
          className={classes.root}
        >
          <h3
            style={{
              color: "white",
              background: "#ff8c00",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
          >
            <IconButton
              style={{ paddingRight: "25px" }}
              color="inherit"
              onClick={() => history.push("/nxtdashboard")}
            >
              <ArrowBackIcon />
            </IconButton>
            Renew Pack
          </h3>
          <br></br>
          <center>
            <h4>Your Active Existing Pack</h4>
            &nbsp;
            <div>
              <table>
                <tr>
                  <th style={{ width: "30vw" }}>Pack Name</th>
                  <th style={{ width: "30vw" }}>Pack Type</th>
                  <th style={{ width: "30vw" }}>Price</th>
                </tr>
                {comp}
              </table>
              {/* <div>
<Link href="/addComplaint" onClick={'/addComplaint'}>
  <Fab  aria-label="add" className={classes.margin}>
  
    <AddIcon />
  </Fab></Link>
</div> */}

              {/* <Container>
  <Link href="/addComplaint">
    <Fab aria-label="add" tooltip="Add Complaint" className={classes.margin}>

      <AddIcon />
    </Fab></Link>
</Container> */}
            </div>
            <div
              style={{ marginTop: "20px", marginBottom: "20px", color: "blue" }}
            >
              Total Amount (Incl GST) :{" "}
              <span
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  color: "red",
                }}
              >
                {" "}
                {"\u20B9"} {Dates} /-
              </span>
            </div>
            &nbsp;
            <button
              style={{
                color: "white",
                backgroundColor: "#ff8c00",
                width: "250px",
                textDecoration: "none",
              }}
              type="button"
              className="btn btn-lg btn-block"
              onClick={() => nxtpage()}
            >
              Renew{" "}
            </button>
            &nbsp;
          </center>
        </div>
      )}
      <div style={{ display: "none" }}>
        <Success name={Category} />
      </div>
    </div>
  );
}
