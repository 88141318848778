import React from "react";
// import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import STBPayment from "./STBPayment";
import Adds from "./adds";
import Nxtstbpayment from "./Nxtstbpayment";
// import Drawer from '@material-ui/core/Drawer';
// import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
// import IconButton from '@material-ui/core/IconButton';
// import { mainListItems, secondaryListItems } from './itemList';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "#F5F5F5",
  },

  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 220,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(true);
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  // const handleDrawerClose = () => {
  //     setOpen(false);
  // };

  return (
    <div className={classes.root}>
      {/* <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>{mainListItems}</List>
                <Divider />
                <List>{secondaryListItems}</List>
            </Drawer> */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={4}>
            {/* <Grid item xs={12} md={8} lg={8}>
                            <Paper className={fixedHeightPaper}>
                                <STBDetail />
                            </Paper>
                        </Grid>
                        
                        <Grid item xs={12} md={4} lg={4}>
                            <Paper className={classes.paper}>
                                <RecentComplaint />
                            </Paper>
                        </Grid> */}

            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                {localStorage.getItem("nxt") ? (
                  <Nxtstbpayment rt={localStorage.getItem("ec")} />
                ) : (
                  <STBPayment />
                )}
              </Paper>
            </Grid>
            {/* <Grid item xs={12} md={3} lg={3}>
                            <Paper className={classes.paper}>
                                <Adds />
                            </Paper>
                        </Grid> */}
          </Grid>
          {/* <Box pt={4}>
                        <Copyright />
                    </Box> */}
        </Container>
      </main>
    </div>
  );
}
