import React, { useState } from "react";
import "./backimg.css";
import AuthApi from "../services/authApi";
import AuthApi3 from "../services/authApi";
import logo from "./../images/mpayicon2.png";
import logo1 from "./../images/heart1.png";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function Login() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});
  const history = useHistory();
  //const [ram, setRam] = React.useState(0);

  const handlePhoneNumber = (e) => {
    //e.preventDefault()
    setPhoneNumber(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      console.log("do validate");
      setPhoneNumber(event.target.value);

      event.preventDefault();
      signIn();
    }
  };

  const signIn = async (event) => {
    // eslint-disable-next-line no-unused-vars
    //event.preventDefault()
    let isValid = true;
    if (phoneNumber === "") {
      setErrors({
        phoneNumber: <font color="red">Phone number can't be empty.</font>,
      });
      isValid = false;
      return;
    }
    let userData = {
      records: [
        {
          phone: phoneNumber,
        },
      ],
    };
    console.log(JSON.stringify(userData));
    await AuthApi3.login(userData)
      .then((response) => {
        console.log(response);
        if (response.data.p_out_mssg_flg === "S") {
          toast.error("Please Wait...", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          console.log("authentication is successful");
          if (response.data.L_MSO_ID === 4) {
            localStorage.setItem("phoneNumber", phoneNumber);
            localStorage.setItem("custid", response.data.L_sms_cust_id);
            localStorage.setItem("lcocode", response.data.L_Merchant_Salt);
            localStorage.setItem("sms_cust_id", response.data.L_sms_cust_id);
            localStorage.setItem("Password", response.data.L_password);
            localStorage.setItem("ownerId", response.data.L_OPERATOR_ID);
            localStorage.setItem(
              "payment_cust_num",
              response.data.L_cust_num_cw
            );
            localStorage.setItem(
              "cancellation_enabled",
              response.data.L_cancellation_enabled
            );
            localStorage.setItem("payment_cust_name", response.data.L_cw_name);
            localStorage.setItem("BULK_IMPORT_FLAG", "Y");
            history.push("/nxtotp");
          } else {
            localStorage.setItem("phoneNumber", phoneNumber);
            history.push("/otpPage");
          }
        } else {
          alert(
            "Your number is not registered. Please check with your cable operator."
          );
          //setRam(0);
        }
      })
      .catch((e) => {
        // history.push('/unauthorized');
        alert("Internet issue");
        console.log(e);
      });
  };

  return (
    <div className="outer">
      <div className="inner">
        <div className="body">
          <form>
            <center>
              {" "}
              <img src={logo} alt="logo" width="100" height="50" />
            </center>
            <span className="larger">
              Mobi<span className="spcolor">Online</span> Payment
            </span>
            <hr />
            &nbsp;
            <div className="form-group">
              <label className="medium">Enter Phone Number :</label>
              <input
                style={{ borderRadius: "15px" }}
                type="tel"
                id="phoneNumber"
                onChange={handlePhoneNumber}
                className="form-control"
                onKeyDown={handleKeyDown}
                pattern="[1-9]{1}[0-9]{9}"
                maxlength="10"
                placeholder="Phone Number"
                required
              />

              <div className="text-danger">{errors.phoneNumber}</div>
            </div>
            &nbsp;
            <button
              style={{ backgroundColor: "#ff8c00" }}
              type="button"
              className="btn btn-lg btn-block"
              onClick={() => signIn()}
            >
              Continue.
            </button>
            &nbsp;
            {/* <p className="forgot-password text-right">
          Forgot <a href="#">password?</a>
        </p> */}
          </form>
          <center>
            <p>
              Made with <img src={logo1} alt="logo" width="20" height="20" /> in
              India!
            </p>
          </center>
        </div>
      </div>
    </div>
  );
}

export default Login;
