import React , {useState} from 'react'
import swal from 'sweetalert';
import Loader from "react-loader-spinner";

export default function Failure() {
    const [loader, setLoader] = useState(true)
    swal("Oops", "Something Went wrong", "error")
    return (
        <div style={{background:'white',height:'100vh'}}>
            <div style={{position:'absolute',left:0,right:0,top:'50%',bottom:0}}>
            <center>
            {loader ?  <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        
      /> : null}
      </center>
      </div>
        </div>
    )
}
