import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
//import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles"; 
import axios from "axios";

import swal from "sweetalert";
import AuthApi1 from "../services/authApi";

import AuthApi from "../services/authApi";
import "../subcriptionDetail/addComplaint.css";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";
toast.configure();

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    background: "white",
    width: "100%",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 280,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 280,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Nxtexistingpath() {
  const classes = useStyles();
  const history = useHistory();
  const [Category, setCategory] = React.useState([]);
  const [Date, setDate] = React.useState("");
  const [loader, setLoader] = useState(true);
  const [pay_loader, set_pay_Loader] = useState(false);

  useEffect(() => {
    sync_pack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sync_pack = async () => {
    
    let data= JSON.stringify({
      lcousername: localStorage.getItem("lcocode"),
      owner_id: parseInt(localStorage.getItem("ownerId")),
      nxt_id: parseInt(localStorage.getItem("sms_cust_id")),
      phone: localStorage.getItem("phoneNumber"),
      cust_num: parseInt(localStorage.getItem("customerNumber")),
    });
   
    

      await AuthApi1.getb2cSync((data))
      .then((response) => {
        console.log('Sync Data111');
        console.log(response);
        getDetails();
  
      })
      .catch((e) => {
   
        console.log(e);
      });

    
  };
  const getDetails = async () => {
    let x = {
      customerId: localStorage.getItem("customerNumber"),
      operatorId: localStorage.getItem("ownerId"),
      stbId: localStorage.getItem("stdid"),
    };

    var config = {
      method: "post",
      url: "https://ld3igodwbj.execute-api.us-west-2.amazonaws.com/prod/cableguy2-get-active-packs-test",

      data: x,
    };

    axios(config).then(function (response) {
      console.log(response.data.active_pac);
      setCategory(response.data.active_pac);
      const comps = response.data.active_pac.map((value) =>
        parseFloat(value.packTotalPrice)
      );
     
      localStorage.setItem(
        "active_pac",
        JSON.stringify(response.data.active_pac)
      );
      console.log(response.data);
      console.log('dataaaaa');
      console.log(comps.reduce((a, b) => a + b, 0));
      let x = comps.reduce((a, b) => a + b, 0);
      let y = x === 0 ? "" : x;
      console.log("y" + y);
      let z = parseFloat(localStorage.getItem("stddue"));
      let zz = parseFloat(x.toFixed(2));

      localStorage.setItem("sta", z + zz);
      setDate((z + zz).toFixed(2));
      setLoader(false);
    });
  };

  const comp = Category.map((value) => (
    <tr>
      <td>{value.packName}</td>
      <td>{value.packType}</td>
      <td>
        {"\u20B9"} {value.packTotalPrice}{" "}
      </td>
    </tr>
  ));

  const NXTActivate=async()=>{
    let data1= JSON.stringify({
      "lcousername": localStorage.getItem("lcocode"),
      stb_id:localStorage.getItem("stdid"),
         phone: localStorage.getItem("phoneNumber"),
      owner_id: parseInt(localStorage.getItem("ownerId")),
      agent_id:"1",
      cust_num:localStorage.getItem("customerNumber"),
      nxt_id:localStorage.getItem("sms_cust_id"),
    });
    console.log(data1);
 

  await AuthApi1.NXTActivation(data1)
    .then((response) => {
      set_pay_Loader(false);
      swal("Success", "Your Request has been Successfully Submitted", "success")
      history.push("/nxtdashboard")
      console.log(response); 
    })
    .catch((e) => {
 
      console.log(e);
    }); 
  }

  const nxtpage = async () => {
    set_pay_Loader(true);
    let data= JSON.stringify({
      cust_num: localStorage.getItem("customerNumber"),
      operator_id: parseInt(localStorage.getItem("ownerId"))
      
    });
    await AuthApi1.manageRenew((data))
    .then((response) => {
       if(response.data.p_out_mssg_flg==="S")
       {
        NXTActivate();
  
        
       }
       else
       {
        set_pay_Loader(false);
        swal(
          "Alert",
          `Something Went Wrong....`,
          "warning"
        );
       }
    
    })
    .catch((e) => {
 
      console.log(e);
    });

    console.log(data);
  };
  return (
    <div>
      <div
        style={{ backgroundColor: "#f5f5f5", height: "100vh" }}
        className={classes.root}
      >
        <h3
          style={{
            color: "white",
            background: "#ff8c00",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          <IconButton
            style={{ paddingRight: "25px" }}
            color="inherit"
            onClick={() => history.push("/nxtrenewalchannel")}
          >
            <ArrowBackIcon />
          </IconButton>
          Renew Pack
        </h3>
        <br></br>
        <center>
          <h5>Your Active Existing Pack</h5>
          <div>
            {loader ? (
              <div>
                <center>
                  {" "}
                  <Loader
                    type="TailSpin"
                    color="#ff8c00"
                    height={100}
                    width={100}
                  />{" "}
                </center>
              </div>
            ) : (
              <div style={{ height: "70vh", overflow: "auto" }}>
                <table>
                  <tr>
                    <th style={{ width: "30vw" }}>Pack Name</th>
                    <th style={{ width: "30vw" }}>Pack Type</th>
                    <th style={{ width: "30vw" }}>Price</th>
                  </tr>
                  {comp}
                </table>
              </div>
            )}
            {/* <div>
<Link href="/addComplaint" onClick={'/addComplaint'}>
  <Fab  aria-label="add" className={classes.margin}>
  
    <AddIcon />
  </Fab></Link>
</div> */}

            {/* <Container>
  <Link href="/addComplaint">
    <Fab aria-label="add" tooltip="Add Complaint" className={classes.margin}>

      <AddIcon />
    </Fab></Link>
</Container> */}
          </div>
          <div
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              color: "blue",
              fontSize: "21px",
            }}
          >
            Total Amount (Incl GST) :{" "}
            <span
              style={{ marginTop: "20px", marginBottom: "20px", color: "red" }}
            >
              {" "}
              {"\u20B9"} {Date} /-
            </span>
          </div>
          <div>
            {pay_loader ? (
              <div>
                <center>
                  {" "}
                  <Loader
                    type="TailSpin"
                    color="#ff8c00"
                    height={100}
                    width={100}
                  />{" "}
                </center>
              </div>
            ) : (
              <button
              style={{
                color: "white",
                backgroundColor: "#ff8c00",
                width: "250px",
                textDecoration: "none",
              }}
              type="submit"
              className="btn btn-lg btn-block"
              onClick={() => nxtpage()}
            >
              Renew{" "}
            </button>
            )}
            
          </div>
      
          &nbsp;
        </center>
      </div>
    </div>
  );
}
