import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Complaint from "./../images/notification.png";
import Avatar from "@material-ui/core/Avatar";
import { Container } from "react-floating-action-button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Link from "@material-ui/core/Link";
import Loader from "react-loader-spinner";
import AuthApi from "../services/authApi";

import AuthApi1 from "../services/authApi";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: "auto",
    background: "white",
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: "#ff7700",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "23.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    paddingLeft:"20px"
  },
  thirdHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    paddingLeft:"60px"
  }, 
}));

export default function ControlledAccordions() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [comp, setComp] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getItems = async () => {
    try {
      let userData = {
        operator_id: parseInt(localStorage.getItem("ownerId")),
        "dml_type":"GET"
      };
      console.log(userData);
      await AuthApi1.cancelRefund(JSON.stringify(userData)).then((response) => {
        console.log(response.data);
        if (response.data !== undefined) {
          setLoading(true);
          let plans = response.data;

          let planslist = plans.map((plan) => {
            return {
              details: plan.NAME,
              remarks: plan.subs_desc,
              comp_date: plan.REQUESTED_DATE,
              DAYS_REMAINING:plan.DAYS_REMAINING,
              REFUNDED_AMOUNT:plan.REFUNDED_AMOUNT,
              REFUNDED_DATE:plan.REFUNDED_DATE,
              CUSTOMER_ID:plan.CUSTOMER_ID,
              STB_NUMBER:plan.STB_NUMBER

            };
          });
          console.log(planslist);
          setComp(planslist);
        } else {
          alert("No complaints");
        }
      });
    } catch (error) {}
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const na = "---";
  const listItems = comp.map((plan, panel) => (
    <Accordion
      expanded={expanded === panel + 1}
      onChange={handleChange(panel + 1)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel+1-content"
        id="panel+1-header"
      >
        <Avatar alt="Tv icon" src={Complaint} />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Typography style={{ paddingTop: "8px" }} className={classes.heading}>
          {" "}
          {plan.details}
        </Typography>
        <Typography style={{ paddingTop: "8px" }} className={classes.thirdHeading}>
          {" "}
          Customer_Id :{" "}
          {plan.CUSTOMER_ID}
        </Typography>
        <Typography style={{ paddingTop: "8px" }} className={classes.thirdHeading}>
          {" "}
          STB_Number :{" "}
          {plan.STB_NUMBER}
        </Typography>
        <Typography
          style={{ paddingTop: "8px" }}
          className={classes.secondaryHeading}
        >
          {"                   "}
          Requested Date :{" "}
          {new Intl.DateTimeFormat("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(new Date(plan.comp_date))}
        </Typography>
        <Typography
          style={{ paddingTop: "8px" }}
          className={classes.thirdHeading}
        >
          CHANNEL :{" "}
           {plan.remarks}
        </Typography>
      
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          Days Remaining : {plan.DAYS_REMAINING === "" ? na : plan.DAYS_REMAINING}
        </Typography>
      </AccordionDetails>
      <AccordionDetails>
        <Typography>
          Amount to be Refund : {plan.REFUNDED_AMOUNT === "" ? na : plan.REFUNDED_AMOUNT}
        </Typography>
      </AccordionDetails>
    </Accordion>
  ));

  const history = useHistory();
  const lol = () => {
    localStorage.getItem("nxt")
      ? history.push("/nxt")
      : history.push("/nxt");
  };
  return (
    <div className={classes.root}>
      <h3
        style={{
          color: "white",
          background: "#ff8c00",
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        <IconButton
          style={{ paddingRight: "25px" }}
          color="inherit"
          onClick={() => lol()}
        >
          <ArrowBackIcon />
        </IconButton>
        STB Cancellation History
      </h3>
      {/* <div>
     <Link href="/addComplaint" onClick={'/addComplaint'}>
        <Fab  aria-label="add" className={classes.margin}>
        
          <AddIcon />
        </Fab></Link>
      </div> */}
      {!loading ? (
        <div
          style={{
            width: "100%",
            height: "100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "200px",
          }}
        >
          <Loader type="Oval" color=" #ff8c00" height="70" width="70" />
        </div>
      ) : (
        listItems
      )}

    
    </div>
  );
}
