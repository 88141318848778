import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflow: "auto",
    background: "white",
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: "#ff7700",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledAccordions() {
  const classes = useStyles();

  const history = useHistory();
  const lol = () => {
    localStorage.getItem("nxt")
      ? history.push("/nxtdashboard")
      : history.push("/dashboard");
  };
  return (
    <div className={classes.root}>
      <h3
        style={{
          color: "white",
          background: "#ff8c00",
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        <IconButton
          style={{ paddingRight: "25px" }}
          color="inherit"
          onClick={() => lol()}
        >
          <ArrowBackIcon />
        </IconButton>
        Terms and Condition
      </h3>
      <h4
        style={{
          fontWeight: "bold",
          paddingLeft: "20px",
          paddingRight: "10px",
        }}
      >
        Introduction
      </h4>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        These Terms will be applied fully and affect to your use of this App. By
        using this app, you agreed to accept all terms and conditions written in
        here. You must not use this App if you disagree with any of these
        Standard Terms and Conditions.
      </h6>
      <hr></hr>

      <h4
        style={{
          fontWeight: "bold",
          paddingLeft: "20px",
          paddingRight: "10px",
        }}
      >
        Intellectual Property Rights
      </h4>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        Other than the content you own, under these Terms, MobiCollector
        Solutions Pvt Ltd. and/or its licensors own all the intellectual
        property rights and materials contained in this App.
      </h6>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        You are granted limited license only for purposes of viewing the
        material contained on this App.
      </h6>
      <hr></hr>

      <h4
        style={{
          fontWeight: "bold",
          paddingLeft: "20px",
          paddingRight: "10px",
        }}
      >
        Restrictions
      </h4>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        You are specifically restricted from all of the following:
      </h6>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        1 . publishing any App/Website material in any other media;
      </h6>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        2 . selling, sublicensing and/or otherwise commercializing any Website
        material;
      </h6>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        3 . publicly performing and/or showing any App/Website material;
      </h6>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        4 . Using this App/Website in any way that is or may be damaging to this
        App/Website;
      </h6>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        5 . Using this App/Website in any way that impacts user access to this
        App/Website;
      </h6>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        6 . Using this App/Website contrary to applicable laws and regulations,
        or in any way may cause harm to the App/Website, or to any person or
        business entity;
      </h6>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        7 . Engaging in any data mining, data harvesting, data extracting or any
        other similar activity in relation to this Website;
      </h6>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        8 . Using this App/Website to engage in any advertising or marketing.
      </h6>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        Certain areas of this App/Website are restricted from being access by
        you and MobiCollector Solutions Pvt Ltd. may further restrict access by
        you to any areas of this App/Website, at any time, in absolute
        discretion. Any user ID and password you may have for this App/Website
        are confidential and you must maintain confidentiality as well.
      </h6>
      <hr></hr>

      <h4
        style={{
          fontWeight: "bold",
          paddingLeft: "20px",
          paddingRight: "10px",
        }}
      >
        Your Content
      </h4>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        In these App/Website Standard Terms and Conditions, “Your Content” shall
        mean any audio, video text, images or other material you choose to
        display on this App/Website. By displaying Your Content, you grant
        MobiCollector Solutions Pvt Ltd. a non-exclusive, worldwide irrevocable,
        sub licensable license to use, reproduce, adapt, publish, translate and
        distribute it in any and all media.
      </h6>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        Your Content must be your own and must not be invading any third-party's
        rights. MobiCollector Solutions Pvt Ltd. reserves the right to remove
        any of Your Content from this App/Website at any time without notice.
      </h6>
      <hr></hr>

      <h4
        style={{
          fontWeight: "bold",
          paddingLeft: "20px",
          paddingRight: "10px",
        }}
      >
        No warranties
      </h4>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        This App/Website is provided “as is,” with all faults, and MobiCollector
        Solutions Pvt Ltd. express no representations or warranties, of any kind
        related to this App/Website or the materials contained on this
        App/Website. Also, nothing contained on this App/Website shall be
        interpreted as advising you.
      </h6>
      <hr></hr>

      <h4
        style={{
          fontWeight: "bold",
          paddingLeft: "20px",
          paddingRight: "10px",
        }}
      >
        Limitation of liability
      </h4>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        In no event shall MobiCollector Solutions Pvt Ltd., nor any of its
        officers, directors and employees, shall be held liable for anything
        arising out of or in any way connected with your use of this App/Website
        whether such liability is under contract. MobiCollector Solutions Pvt
        Ltd., including its officers, directors and employees shall not be held
        liable for any indirect, consequential or special liability arising out
        of or in any way related to your use of this App/Website.
      </h6>
      <hr></hr>

      <h4
        style={{
          fontWeight: "bold",
          paddingLeft: "20px",
          paddingRight: "10px",
        }}
      >
        Indemnification
      </h4>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        You hereby indemnify to the fullest extent MobiCollector Solutions Pvt
        Ltd. from and against any and/or all liabilities, costs, demands, causes
        of action, damages and expenses arising in any way related to your
        breach of any of the provisions of these Terms.
      </h6>
      <hr></hr>

      <h4
        style={{
          fontWeight: "bold",
          paddingLeft: "20px",
          paddingRight: "10px",
        }}
      >
        Severability
      </h4>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        If any provision of these Terms is found to be invalid under any
        applicable law, such provisions shall be deleted without affecting the
        remaining provisions herein.
      </h6>
      <hr></hr>

      <h4
        style={{
          fontWeight: "bold",
          paddingLeft: "20px",
          paddingRight: "10px",
        }}
      >
        Variation of Terms
      </h4>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        MobiCollector Solutions Pvt Ltd. is permitted to revise these Terms at
        any time as it sees fit, and by using this App/Website you are expected
        to review these Terms on a regular basis.
      </h6>
      <hr></hr>

      <h4
        style={{
          fontWeight: "bold",
          paddingLeft: "20px",
          paddingRight: "10px",
        }}
      >
        Assignment
      </h4>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        The MobiCollector Solutions Pvt Ltd. is allowed to assign, transfer, and
        subcontract its rights and/or obligations under these Terms without any
        notification. However, you are not allowed to assign, transfer, or
        subcontract any of your rights and/or obligations under these Terms.
      </h6>
      <hr></hr>

      <h4
        style={{
          fontWeight: "bold",
          paddingLeft: "20px",
          paddingRight: "10px",
        }}
      >
        Entire Agreement
      </h4>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        These Terms constitute the entire agreement between MobiCollector
        Solutions Pvt Ltd. and you in relation to your use of this App/Website,
        and supersede all prior agreements and understandings.
      </h6>
      <hr></hr>

      <h4
        style={{
          fontWeight: "bold",
          paddingLeft: "20px",
          paddingRight: "10px",
        }}
      >
        Governing Law & Jurisdiction
      </h4>
      <h6 style={{ paddingLeft: "20px", paddingRight: "10px" }}>
        These Terms will be governed by and interpreted in accordance with the
        laws of the State of Country, and you submit to the non-exclusive
        jurisdiction of the state and federal courts located in India for the
        resolution of any disputes.
      </h6>
    </div>
  );
}
