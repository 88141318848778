import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import '../subcriptionDetail/addComplaint.css'
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import Loader from "react-loader-spinner";
toast.configure()


const useStyles = makeStyles((theme) => ({
  root: {

    overflow: 'auto',
    background: 'white',
    height:'100vh',
    width: '100%',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 280,
    },

  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 280,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


export default function Nxtmodifiedpackdetails() {
    const classes = useStyles();
  const history = useHistory();
  const [a, setA] = useState([]);
    const [b, setB] = useState([]);
    const [c, setC] = useState([]);
    const [mso, setMso] = useState([]);
    const [pc, setPc] = useState([]);
    const [bft, setBft] = useState([]);
    const [active, setActive] = useState([]);
    const [totalamount , setTotalamount] = useState(0)
    const [totalamountewithtax , setTotalamountwithtax] = useState(0)
    const [totalamountwithbase , setTotalamountwithbase] = useState(0)
    const [loader, setLoader] = useState(true)
  useEffect(() => {
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getItems = () => {
    var config = {
      method: 'post',
      url: 'https://91z8byyrzi.execute-api.ap-south-1.amazonaws.com/prod/rupayee-getb2cpacklist-new',
     
      data : {"records":[{"operator_id":localStorage.getItem("ownerId")}]}
    };
    
    axios(config)
    .then(function (response) {
      setC(response.data.mso_bouquet);
      setB(response.data.pay_channel)
      setA(response.data.base_pack_fta)
      const obj = localStorage.getItem("naa")
      const obj1 = localStorage.getItem("nab")
      const obj2 = localStorage.getItem("nac")
      const myArr = obj.split(",")//base pack
      const myArr1 = obj1.split(",")//mso
      const myArr2 = obj2.split(",")//paychanels
      console.log(myArr1)
      console.log(myArr2)
      console.log(myArr)
      var x = response.data.mso_bouquet
      var x1 = response.data.pay_channel
      console.log(x)
      var doubled = myArr.map((number) => x1.find(element => element.pack_id == number));
      console.log(doubled)
      setMso(doubled)
      
      var doubled1 = myArr1.map((number) => x.find(element => element.pack_id == number));
      console.log(x1)
      setPc(doubled1)
      var x2 = response.data.base_pack_fta
      var doubled2 = myArr2.map((number) => x2.find(element => element.pack_id == number));
      console.log(doubled2)
      setBft(doubled2)
      console.log(doubled2)
      console.log(response.data.base_pack_fta)
      console.log(doubled2)
      const comps = doubled.map((value) => parseFloat(value == undefined ? 0 : value.total_amount))

      console.log(comps)
     

      console.log(
          comps.reduce((a, b) => a + b, 0)
        )
        let xx = comps.reduce((a, b) => a + b, 0)
 //this vairable is created for the request in Success.js 
 const compstax = doubled.map((value) => parseFloat(value == undefined ? 0 : value.total_tax))

      console.log(compstax,"ctt")

      const compsbase = doubled.map((value) => parseFloat(value == undefined ? 0 : value.base_price))

      console.log(compsbase)
     

      console.log(
          comps.reduce((a, b) => a + b, 0)
        )
        let xxtax = compstax.reduce((a, b) => a + b, 0)

        let xxbase = compsbase.reduce((a, b) => a + b, 0)

        //--------------------------------------------------------
        const compss = doubled1.map((value) => parseFloat(value == undefined ? 0 : value.total_amount))

      console.log(compss)
        
      console.log(
          compss.reduce((a, b) => a + b, 0)
        )
        let xx1 = compss.reduce((a, b) => a + b, 0)
        //this vairable is created for the request in Success.js 
        const compssTax = doubled1.map((value) => parseFloat(value == undefined ? 0 : value.total_tax))

        console.log(compssTax,"ct")
          
        const compssBase = doubled1.map((value) => parseFloat(value == undefined ? 0 : value.base_price))

        console.log(compssBase)
          
        console.log(
            compss.reduce((a, b) => a + b, 0)
          )
          let xx1Tax = compssTax.reduce((a, b) => a + b, 0)
          let xx1Base = compssBase.reduce((a, b) => a + b, 0)
        //----------------------------------------------------------

        const compses = doubled2.map((value) => parseFloat(value == undefined ? 0 : value.total_amount))

      console.log(compses)
        
      console.log(
          compses.reduce((a, b) => a + b, 0)
        )
        let xx2 = compses.reduce((a, b) => a + b, 0)
        //this vairable is created for the request in Success.js 
        const compsestax = doubled2.map((value) => parseFloat(value == undefined ? 0 : value.total_tax))
        const compsesbase = doubled2.map((value) => parseFloat(value == undefined ? 0 : value.base_price))
      console.log(compses)
      console.log(compsestax,"ct")
      console.log(
          compses.reduce((a, b) => a + b, 0)
        )
        let xx2tax = compsestax.reduce((a, b) => a + b, 0)
        let xx2base = compsesbase.reduce((a, b) => a + b, 0)
//==================================================
        let xa = {"customerId":localStorage.getItem("customerNumber"),"operatorId":localStorage.getItem("ownerId"),"stbId":localStorage.getItem("stdid")}
  
      
        var config = {
            method: 'post',
            url: 'https://ld3igodwbj.execute-api.us-west-2.amazonaws.com/prod/mobileactivepacks_test1',
           
            data : xa
          };
          
          axios(config)
          .then(function (response) {
            console.log(response.data.active_pac1);
            const objc = localStorage.getItem("nad")
            const myAr = objc.split(",")
            var y =response.data.active_pac1
            console.log(response.data.active_pac1)
            var doubledb = myAr.map((number) => y.find(element => element.pack_name == number));
            setActive(doubledb)
            const compa = doubledb.map((value) => parseFloat(value == undefined ? 0 : value.total_amount))

            console.log((xx == undefined ? 0 : xx) + (xx1 == undefined ? 0 : xx1) + (xx2 == undefined ? 0 : xx2) + (xx3 == undefined ? 0 : xx3))
              
            console.log(
                compa.reduce((a, b) => a + b, 0)
              )
              var xx3 = compa.reduce((a, b) => a + b, 0)
              //this vairable is created for the request in Success.js 

              const compatax = doubledb.map((value) => parseFloat(value == undefined ? 0 : value.total_tax))
              const compabase = doubledb.map((value) => parseFloat(value == undefined ? 0 : value.base_price))
              console.log((xx == undefined ? 0 : xx) + (xx1 == undefined ? 0 : xx1) + (xx2 == undefined ? 0 : xx2) + (xx3 == undefined ? 0 : xx3),"heloooooo")
                console.log(compatax,"ct")
              console.log(
                  compa.reduce((a, b) => a + b, 0)
                )
                var xx3tax = compatax.reduce((a, b) => a + b, 0)
                var xx3base = compabase.reduce((a, b) => a + b, 0)
              //-----------------------------------------------
             setTotalamount((xx == undefined ? 0 : xx) + (xx1 == undefined ? 0 : xx1) + (xx2 == undefined ? 0 : xx2) + (xx3 == undefined ? 0 : xx3))
             setTotalamountwithtax((xxtax == undefined ? 0 : xxtax) + (xx1Tax == undefined ? 0 : xx1Tax) + (xx2tax == undefined ? 0 : xx2tax) + (xx3tax == undefined ? 0 : xx3tax))
             setTotalamountwithbase((xxbase == undefined ? 0 : xxbase) + (xx1Base == undefined ? 0 : xx1Base) + (xx2base == undefined ? 0 : xx2base) + (xx3base == undefined ? 0 : xx3base))
             setLoader(false)
          })
        
    })

    
     .catch(function (error) {
       console.log(error);
     });
  }

  const undifinedcheck =() =>{
    if(bft == undefined){
      var x =[...mso,...pc,...active]
      console.log(x)
    }else if(mso == undefined){
      var x =[...bft,...pc,...active]
    }else if(pc == undefined){
      var x =[...bft,...mso,...active]

    }else if(active == undefined){
      var x =[...bft,...mso,...pc]
    }else{
      var x =[...bft,...mso,...pc,...active]
    }
    
    localStorage.setItem("active_pac",JSON.stringify(x))
  }

  const nxtpage =() =>{
    if(bft[0] == undefined){
      var x =[...mso,...pc,...active]
      console.log(x)
    }else if(mso[0] == undefined){
      var x =[...bft,...pc,...active]
    }else if(pc[0] == undefined){
      console.log("working")
      var x =[...bft,...mso,...active]

    }else if(active[0] == undefined){
      var x =[...bft,...mso,...pc]
    }else if(active[0] == undefined && pc[0] == undefined){
      var x =[...bft,...mso]
    }else if(active[0] == undefined && mso[0] == undefined){
      var x =[...bft,...pc]
    }else if(active[0] == undefined && bft[0] == undefined){
      var x =[...bft,...mso]
    }
    else if(bft[0] == undefined && active[0] == undefined){
      var x =[...mso,...pc]
    }else if(bft[0] == undefined && pc[0] == undefined){
      var x =[...mso,...active]
    }else if(bft[0] == undefined && mso[0] == undefined){
      var x =[...pc,...active]
    }else if(mso[0] == undefined && pc[0] == undefined){
      var x =[...bft,...active]
    }else if(mso[0] == undefined && bft[0] == undefined){
      var x =[...pc,...active]
    }else if(mso[0] == undefined && active[0] == undefined){
      var x =[...pc,...bft]
    }else if(pc[0] == undefined && bft[0] == undefined){
      var x =[...pc,...mso]
    }else if(pc[0] == undefined && mso[0] == undefined){
      var x =[...active,...bft]
    }else if(pc[0] == undefined && active[0] == undefined){
      var x =[...mso,...bft]
    }else{
      var x =[...bft,...mso,...pc,...active]
      console.log("not working")
      console.log(pc)
    }

    
    localStorage.setItem("active_pac",JSON.stringify(x))
    console.log(x)
    console.log(totalamountwithbase,totalamountewithtax,totalamount)
    localStorage.setItem("nxtrenewandmodifytotalammount",totalamount)
  localStorage.setItem("nxtrenewandmodifytotaltax",totalamountewithtax)
  localStorage.setItem("nxtrenewandmodifytotalbase",totalamountwithbase)
  localStorage.setItem("ec","modifyandrenew")
  localStorage.setItem("paymentamount",totalamount)
    history.push('/subDetail')
  }
    return (
        <div style={{background:'white',height:'100vh'}}>
           
            {loader ?  <div style={{position:'absolute',left:0,right:0,top:'50%',bottom:0,background:'white'}}>
            <center> <Loader
        type="TailSpin"
        color="orange"
        height={100}
        width={100}
        
      /> </center>
      </div>: 
          <div style={{ backgroundColor: '#f5f5f5', height: "100%" }} className={classes.root}>
      <h3 style={{ color: 'white', background: '#ff8c00', paddingTop: '8px', paddingBottom: '8px' }}>
        <IconButton style={{ paddingRight: '25px' }} color="inherit" onClick={() => history.push('/nxtdashboard')}>
          <ArrowBackIcon />
        </IconButton>Renew Pack</h3>
      <br></br>
      <center>
          Your Active Existing Pack
          &nbsp;
      <div
        
      >




<table>
  <tr>
    <th style={{width:'30vw'}}>Pack Name</th>
    <th style={{width:'30vw'}}>Pack Type</th>
    <th style={{width:'30vw'}}>Price</th>
  </tr>
 
  {bft.map((value) =>
<tr style={value == undefined ? {display:'none'} : null}>
    <td>{value == undefined ? null : value.pack_name}</td>
    <td>{value == undefined ? null : value.pack_type}</td>
    <td> {value == undefined ? null :  `${"\u20B9"}${value.total_amount}`} </td>
  </tr>
  
)}
 {mso.map((value) =>
<tr style={value == undefined ? {display:'none'} : null}>
    <td>{value == undefined ? null : value.pack_name }</td>
    <td>{value == undefined ? null : value.pack_type }</td>
    <td> {value == undefined ? null :  `${"\u20B9"}${value.total_amount}` } </td>
  </tr>
)}
 {pc.map((value) =>
<tr style={value == undefined ? {display:'none'} : null}>
    <td>{value == undefined ? null : value.pack_name}</td>
    <td>{value == undefined ? null : value.pack_type}</td>
    <td> {value == undefined ? null : `${"\u20B9"}${value.total_amount}`} </td>
  </tr>
)}
 {active.map((value) =>
<tr style={value == undefined ? {display:'none'} : null}>
    <td>{value == undefined ? null : value.pack_name}</td>
    <td>{value == undefined ? null : value.pack_type}</td>
    <td> {value == undefined ? null :  `${"\u20B9"}${value.total_amount}`} </td>
  </tr>
)}
</table>
{/* <div>
<Link href="/addComplaint" onClick={'/addComplaint'}>
  <Fab  aria-label="add" className={classes.margin}>
  
    <AddIcon />
  </Fab></Link>
</div> */}


{/* <Container>
  <Link href="/addComplaint">
    <Fab aria-label="add" tooltip="Add Complaint" className={classes.margin}>

      <AddIcon />
    </Fab></Link>
</Container> */}
</div>

<div style={{ marginTop:'20px' , marginBottom:'20px' , color: 'blue'}} >Total Amount (Incl GST)  : <span style={{ marginTop:'20px' , marginBottom:'20px' , color: 'red'}} > {"\u20B9"} {totalamount} /-</span></div>

&nbsp;


<button style={{color: "white", backgroundColor: '#ff8c00', width: '260px', textDecoration: 'none' }}
          type="button" onClick={()=>nxtpage()} className="btn btn-lg btn-block" >Renew </button>
&nbsp;
</center>

    </div> 
    
}
  

        </div>
    )
}

