import React, { useState,useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Avatar from '@material-ui/core/Avatar';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Complaint from './../images/notification.png';
import AccordionDetails from '@material-ui/core/AccordionDetails';

//import { makeStyles } from '@material-ui/core/styles';
//import Accordion from '@material-ui/core/Accordion';
//import AccordionDetails from '@material-ui/core/AccordionDetails';
//import AccordionSummary from '@material-ui/core/AccordionSummary';
//import Typography from '@material-ui/core/Typography';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import Complaint from './../images/notification.png';
//import Avatar from '@material-ui/core/Avatar';
//import { Container, Button } from 'react-floating-action-button'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import Link from '@material-ui/core/Link';
import Loader from 'react-loader-spinner';
import AuthApi from '../services/authApi';
//import { IconButton } from '@material-ui/core';
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom";



//import Avatar from '@material-ui/core/Avatar';
//import Checkbox from "@material-ui/core/Checkbox";
//import FormControlLabel from "@material-ui/core/FormControlLabel";
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/Delete';
import swal from 'sweetalert';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const useStyles = makeStyles((theme) => ({
  root: {
      display: 'flex',
      background: 'white'
  },
 
  
  content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
  },
  container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
  },
  paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
  },
  fixedHeight: {
      height: 220,
  },
}));

const useStyle = makeStyles((theme) => ({
  root: {
    height: '100vh',
    overflow: 'auto',
    background: 'white',
    width: '100%',
  },
  margin: {
    margin: theme.spacing(1),
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: '#ff7700'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));
export default function Nxtrenewinner() {
    const [value, setValue] = React.useState('one');
    const classes = useStyles();
    const [cSelected, setCSelected] = useState('0000');
    const [deleted, setDeleted] = useState(true);
    const [aSelected, setASelected] = useState([]);
    const [bSelected, setBSelected] = useState([]);
    const [dSelected, setDSelected] = useState([]);
    const [a, setA] = useState([]);
    const [b, setB] = useState([]);
    const [c, setC] = useState([]);
    const [d, setD] = useState([]);
    const [adef, setAdef] = useState([]);
    const [bdef, setBdef] = useState([]);
    const [cdef, setCdef] = useState([]);
    const [ddef, setDdef] = useState([]);
    const [sa, setSa] = useState('');
    const [sb, setSb] = useState('');
    const [sc, setSc] = useState('');
    const [category_check_list, setCategoryCheckList] = useState([]);
    const [Category, setCategory] = React.useState([]);
  const [Date, setDate] = React.useState('');
 // console.log(dSelected)
    const history = useHistory()
    const [loader, setLoader] = useState(true)
    const [loadera, setLoadera] = useState(true)
    useEffect(() => {
      getItems();
      getDetails()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const getItems = () => {
      var config = {
        method: 'post',
        url: 'https://91z8byyrzi.execute-api.ap-south-1.amazonaws.com/prod/rupayee-getb2cpacklist-new',
       
        data : {"records":[{"operator_id":localStorage.getItem("ownerId")}]}
      };
      
      axios(config)
      .then(function (response) {
        setC(response.data.mso_bouquet);
        setB(response.data.pay_channel)
        setA(response.data.base_pack_fta)
        setCdef(response.data.mso_bouquet);
        setBdef(response.data.pay_channel)
        setAdef(response.data.base_pack_fta)

       console.log(response.data.base_pack_fta)
       setLoadera(false)
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const getDetails = async () => {
      let x = {"customerId":localStorage.getItem("customerNumber"),"operatorId":localStorage.getItem("ownerId"),"stbId":localStorage.getItem("stdid")}
  
      
      var config = {
          method: 'post',
          url: 'https://ld3igodwbj.execute-api.us-west-2.amazonaws.com/prod/mobileactivepacks_test1',
         
          data : x
        };
        
        axios(config)
        .then(function (response) {
          console.log(response);
          setCategory(response.data.active_pac1)
          const comps = response.data.active_pac1.map((value) => parseFloat(value.total_amount))
  
  console.log(comps)
  console.log(Category)
  const compsname = response.data.active_pac1.map((value) => value.pack_name)
  setD(compsname)
  console.log(compsname)
  console.log(
      comps.reduce((a, b) => a + b, 0)
    )
    let x = comps.reduce((a, b) => a + b, 0)
   let y = x == 0 ? '' : x
  console.log("y" + y)
  let z = parseFloat(localStorage.getItem("stddue"))
  let zz = parseFloat(x.toFixed(2))
  localStorage.setItem("sta",z + zz)
  setDate(x.toFixed(2))
  setLoader(false)
        })
  }
    const categoryCheckboxFilterHandler = e => {
        if (e.target.checked == true){
            setCategoryCheckList([...category_check_list, e.target.value]);
        }else{
            let check_list = [];
            category_check_list.map(check => {
                if (Number(check) != Number(e.target.value)){
                    check_list.push(Number(check));
                }
            });
            setCategoryCheckList(check_list);
        }
    };
    var checked_category_id_list = category_check_list.toString();

    
//const [rSelected, setRSelected] = useState(null);

// const onCheckboxBtnClick = (selected) => {
//   const index = cSelected.indexOf(selected);
//   if (index < 0) {
//     cSelected.push(selected);
//   } else {
//     cSelected.splice(index, 1);
//   }
//   setCSelected([...cSelected]);
// }

const onCheckboxBtnClick =  (event) => {
 
  setCSelected(event.target.value);
  console.log(event.target.value)
}
const onCheckboxBtnClickb = (selected) => {
  const index = bSelected.indexOf(selected);
  if (index < 0) {
    bSelected.push(selected);
  } else {
    bSelected.splice(index, 1);
  }
  setBSelected([...bSelected]);
}

const onCheckboxBtnClicka = (selected) => {
  const index = aSelected.indexOf(selected);
  if (index < 0) {
    aSelected.push(selected);
  } else {
    aSelected.splice(index, 1);
  }
  setASelected([...aSelected]);
}

const onCheckboxBtnClickx = (selected) => {
  const index = dSelected.indexOf(selected);
  if (index < 0) {
    dSelected.push(selected);
  } else {
    dSelected.splice(index, 1);
    
  }
  setDSelected([...dSelected]);
}
const tr = () =>{
 
 let myArrays = d.filter( function( el ) {
    return dSelected.indexOf( el ) < 0;
  } );
  //swal("Oops", "Please Select some pack", "error")
  console.log(myArrays)
  console.log(aSelected,"a")
  console.log(bSelected,"b")
  console.log(cSelected,"c")
  localStorage.setItem("nad",String(myArrays))//active packs
  localStorage.setItem("naa",String(aSelected))//base pack
  localStorage.setItem("nab",String(bSelected))//mso
  localStorage.setItem("nac",String(cSelected))//pay
  history.push("/nwr")

}
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleChangea = (e) => {
      setSa(e.target.value);
      // let x = a.find(element => element.pack_name == e.target.value) 
      //  x == undefined ? [] :  setA(x)
     
      // console.log(a.find(element => element.pack_name == 'FREE PACK 1'))

      console.log(a.find(element => element.pack_name == sa))
     let x = adef.filter(country => {
        return country.pack_name.toLowerCase().includes(e.target.value.toLowerCase())
       })
       console.log(x)
      setA(x)
    };

    const handleChangeb = (event) => {
     // setValue(newValue);
    // setSb(event.target.value)
     let x = bdef.filter(country => {
      return country.pack_name.toLowerCase().includes(event.target.value.toLowerCase())
     })
     console.log(x)
    setB(x)
    };

    const handleChangec = (event) => {
      //setValue(newValue);
      //setSc(event.target.value)
      let x = cdef.filter(country => {
        return country.pack_name.toLowerCase().includes(event.target.value.toLowerCase())
       })
       console.log(x)
      setC(x)

    };

    const onSearcha = () => {
      
     
      console.log(a.find(element => element.pack_name == sa))
     let x = adef.filter(country => {
        return country.pack_name.toLowerCase().includes(sa.toLowerCase())
       })
       console.log(x)
      setA(x)
    }

    const onSearchb = () => {
      setB([b.find(element => element.pack_name == sb) == undefined ? [] :  b.find(element => element.pack_name == sb)])
    }

    const onSearchc = () => {
      setC([c.find(element => element.pack_name == sc) == undefined ? [] :  c.find(element => element.pack_name == sc)])
    }
    

    const comp = Category.map((value) =>
<tr style= { dSelected.find(element => element == value.pack_name) !== undefined ? {display:'none'} : {color:'black'} }>
    <td> {value.pack_name}</td> 
    <td>{value.pack_type}</td>
    <td>{"\u20B9"} {value.total_amount} /-</td>
    <td><button style={{background:'none',outline:'none',border:'none'}} onClick={() => onCheckboxBtnClickx(value.pack_name)}><DeleteIcon/></button></td>
  </tr>
)
    return (
        <div style={{ background: 'white', width: '100vw',height:'100vh'}} >
            {loader ? <div style={{position:'absolute',left:0,right:0,top:'50%',bottom:0}}> <center> <Loader
        type="TailSpin"
        color='#ff8c00'
        height={100}
        width={100}
        
      /></center> </div> : <div>
            <h3 style={{ color: 'white', background: '#ff8c00', paddingTop: '8px', paddingBottom: '8px',width: '100%' }}>
        <IconButton style={{ paddingRight: '25px' }} color="inherit" >
          <ArrowBackIcon onClick={()=>history.push('/nxtdashboard')} />
        </IconButton>Modify & Renew Pack</h3>
      <br></br>
      <center>
          Your Active Existing Pack
          &nbsp;
          Choose Channel to delete
      <div
        
      >




<table>
  <tr>
    <th style={{width:'30vw'}}>Pack Name</th>
    <th style={{width:'30vw'}}>Pack Type</th>
    <th style={{width:'20vw'}}>Price</th>
    <th style={{width:'10vw'}}>Action</th>
  </tr>
  {comp}
  
</table>
{/* <div>
<Link href="/addComplaint" onClick={'/addComplaint'}>
  <Fab  aria-label="add" className={classes.margin}>
  
    <AddIcon />
  </Fab></Link>
</div> */}


{/* <Container>
  <Link href="/addComplaint">
    <Fab aria-label="add" tooltip="Add Complaint" className={classes.margin}>

      <AddIcon />
    </Fab></Link>
</Container> */}
</div>


&nbsp;



&nbsp;
</center>
  <div className={classes.root} >
 
              {/* <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>{mainListItems}</List>
                <Divider />
                <List>{secondaryListItems}</List>
            </Drawer> */}
            
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={4}>
                       
                        {/* <Grid item xs={12} md={8} lg={8}>
                            <Paper className={fixedHeightPaper}>
                                <STBDetail />
                            </Paper>
                        </Grid>
                        
                        <Grid item xs={12} md={4} lg={4}>
                            <Paper className={classes.paper}>
                                <RecentComplaint />
                            </Paper>
                        </Grid> */}
                      
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.paper}>
                            <Tabs>
    <TabList>
      <Tab style={{width:'33%'}}>Base Pack</Tab>
      <Tab style={{width:'33%'}}>Bouquet Packs</Tab>
      <Tab style={{width:'33%'}}>Pay Channels</Tab>
      
    </TabList>

    <TabPanel>
      <div style={{height:'60vh',overflow:'auto',marginBottom:'15px'}}>
    <FormControl component="fieldset">
        <FormLabel component="legend">  <form action="/action_page.php">
      <input type="text" onChange={handleChangea} style={{border:'none'}} placeholder="Search.." name="search"/>
      <button type="button" onClick={()=>onSearcha()}  style={{border:'none',borderRadius:'10px'}}><SearchIcon/></button>
    </form></FormLabel>
        <FormGroup>
         
         
          
          {a == [[]] ? null :  a.map((number) =>
//    <FormControlLabel
//    control={
//      <Checkbox
//      onClick={() => onCheckboxBtnClick(number.pack_id)} checked={cSelected.find(element => element == number.pack_id) !== undefined ? true : false } //active={cSelected.includes(6)}
//      />
//    }
//    label={String((number.pack_name == undefined ? '---' : number.pack_name)  + '--'+ ' ' + `${"\u20B9"}` +  (number.total_amount == undefined ? '---' : number.total_amount) + '/-')}
//  />


<RadioGroup aria-label="quiz" name="quiz" value={cSelected}  onClick={onCheckboxBtnClick} >
          <FormControlLabel value={number.pack_id} active={true} control={<Checkbox checked={cSelected == number.pack_id ? true : false }/>} label={String((number.pack_name == undefined ? '---' : number.pack_name)  + '--'+ ' ' + `${"\u20B9"}` +  (number.total_amount == undefined ? '---' : number.total_amount) + '/-')} />
         
        </RadioGroup>
  )}
         
        </FormGroup>
       
      </FormControl>
      </div>
    </TabPanel>
    <TabPanel >
    <div style={{height:'60vh',overflow:'auto',marginBottom:'15px'}}>
    <FormGroup>
    <FormLabel component="legend">  <form action="/action_page.php">
      <input type="text" onChange={handleChangec} style={{border:'none'}} placeholder="Search.." name="search"/>
      <button type="button" onClick={()=>onSearchc()} style={{border:'none',borderRadius:'10px'}}><SearchIcon/></button>
    </form></FormLabel>
    {c == [[]] ? null :c.map((number) =>
   <FormControlLabel
   control={
     <Checkbox
     onClick={() => onCheckboxBtnClickb(number.pack_id)} 
     checked={bSelected.find(element => element == number.pack_id) !== undefined ? true : false }//active={cSelected.includes(6)}
     />
   }
   label={String((number.pack_name == undefined ? '---' : number.pack_name)  + '--'+ ' ' + `${"\u20B9"}` +  (number.total_amount == undefined ? '---' : number.total_amount) + '/-')}
 />
  )}
  </FormGroup>
  </div>
    </TabPanel>
    <TabPanel >
    <div style={{height:'60vh',overflow:'auto',marginBottom:'15px'}}>
    <FormGroup>
    <FormLabel component="legend">  <form action="/action_page.php">
      <input type="text" onChange={handleChangeb} style={{border:'none'}} placeholder="Search.." name="search"/>
      <button type="button" onClick={()=>onSearchb()} style={{border:'none',borderRadius:'10px'}}><SearchIcon/></button>
    </form></FormLabel>
    {b == [[]] ? null :b.map((number) =>
   <FormControlLabel
   control={
     <Checkbox
     onClick={() => onCheckboxBtnClicka(number.pack_id)}
     checked={aSelected.find(element => element == number.pack_id) !== undefined ? true : false } //active={cSelected.includes(6)}
     />
   }
   label={String((number.pack_name == undefined ? '---' : number.pack_name)  + '--'+ ' ' + `${"\u20B9"}` +  (number.total_amount == undefined ? '---' : number.total_amount) + '/-')}
 />
  )}
  </FormGroup>
  </div>
    </TabPanel>
    <button style={{color: "white", backgroundColor: '#ff8c00', width: '200px', textDecoration: 'none' }}
          type="button" className="btn btn-lg btn-block" onClick={() =>tr()} >Modify and renew </button>
  </Tabs>
                            </Paper>
                        </Grid>

                        {/* <Grid item xs={12} md={3} lg={3}>
                            <Paper className={classes.paper}>
                                <Adds />
                            </Paper>
                        </Grid> */}
                    </Grid>
                    {/* <Box pt={4}>
                        <Copyright />
                    </Box> */}
                </Container>
            </main>
            
        </div>
        </div>

                  }
        </div>





    )
}
