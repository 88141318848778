import React, { useState, useEffect } from "react";

//import axios from 'axios';
import AuthApi from "../services/authApi";
import AuthApi1 from "../services/authApi";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid"; 
import tvlogo from "./../images/tvicon.png";
import { useHistory } from "react-router-dom"; 
 
import Button from '@material-ui/core/Button';
import Loader from "react-loader-spinner";
import swal from 'sweetalert';
import { Container } from "react-floating-action-button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Check from "@material-ui/icons/Check";
import Link from "@material-ui/core/Link";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { format } from 'date-fns';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
} from "@material-ui/core";
// import { ExcelFile, ExcelSheet } from "react-data-export";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    margin: theme.spacing(2),

    borderRadius: "10px",
    padding: "20px",
  },
  tvicon: {
    width: "35%",
    marginLeft: "10px",
  },
  table: {
    minWidth: 650, // Set your desired width
  },
  searchInput: {
    marginBottom: theme.spacing(2),
  },
}));

export default function CANCEL_STB_REPORT() {
  const classes = useStyles();
  const [dashboarddata, setDashboarddata] = useState([]);
  const [data, setData] = useState([]);
  
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
 
  const [lcowallet, setlcowallet] =  React.useState('');
 
  
  const [loader, setLoader] = React.useState(false);

  
 
  const history = useHistory();
  useEffect(() => {
    
    getstbreport();
  }, []);

  const getstbreport=async()=>{
    var data = {
      records: [
        {
          phone: localStorage.getItem("phoneNumber"),
          "flag":"CSR"
        },
      ],
    };

    await AuthApi.getmpaycancelstbreport(data).then((response) => {
      console.log(response.data.stb_details);
   
       setData(response.data.stb_details);
      setFilteredData(response.data.stb_details);
    
    
   
    
     
   }).catch((e)=>{
    setData([]);
   })
  }
  
  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = data.filter((row) =>
      Object.values(row).some(
        (value) => String(value).toLowerCase().includes(term)
      )
    );
    setFilteredData(filtered);
    setPage(0); 
  };
  
  const pageCount = Math.ceil(filteredData.length / rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page changes
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const exportData = filteredData.map((row) => ({
  //   stbNumber: row.field1,
  //   planName: row.field2,
  //   status: row.field3,
  //   remarks: row.field4,
  //   date: row.field5,
  // }));

  const StyledTableCell = ({ children }) => (
    <TableCell style={{ backgroundColor: "orange", color: "white", fontWeight: "bold" }}>
      {children}
    </TableCell>
  );
 

 
  const lol = () =>{
    // localStorage.getItem('nxt') ? history.push("/nxtdashboard") : history.push("/dashboard")
    history.push("/nxt")// 
   }
  
  return (
    <div>
    <div
      style={{
        backgroundColor: "#f5f5f5",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <h3
        style={{
          color: "white",
          background: "#ff8c00",
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        <IconButton style={{ paddingRight: "25px" }} color="inherit" onClick={() => lol()}>
          <ArrowBackIcon />
        </IconButton>
        CANCELLATION STB REPORT
      </h3>
      <br></br>
        <div className={classes.root}>
          <center>
            {" "}
            
            <h4><b>CANCELLATION REPORT</b>
              
            </h4>
          </center>
         
       
          <Grid container>
          <TextField
            className={classes.searchInput}
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearch}
            style={{align:"right"}}
          />
          <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>STB NUMBER</StyledTableCell>
                <StyledTableCell>PLAN NAME</StyledTableCell>
                <StyledTableCell>STATUS</StyledTableCell>
                {/* <StyledTableCell>REMARKS</StyledTableCell> */}
                <StyledTableCell>DATE</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {data.length > 0 ? (
                  data.map((row) => {
                    return(
                    <TableRow key={row.id}>
                      
                      <TableCell>{row.stb_number}</TableCell>
                      <TableCell>{row.subs_desc}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      {/* <TableCell>{row.response}</TableCell> */}
                      <TableCell>{format(new Date(row.cancel_date), 'dd-MM-yyyy')}</TableCell>
                    </TableRow>
                    )
                    })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>No data available</TableCell>
                  </TableRow>
                )}
          </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
           
          </Grid>
          {loader ? (
              <div>
                <center>
                  {" "}
                  <Loader
                    type="TailSpin"
                    color="#ff8c00"
                    height={100}
                    width={100}
                  />{" "}
                </center>
              </div>
            ) : ( <Container>
         
      </Container>)}
        </div>
      </div>
    </div>
  );
}



