import React, { useState, useEffect } from "react";
import "../subcriptionDetail/STBpayment.css";
//import tvlogo from "./../images/tvicon.png";
import { Grid } from "@material-ui/core";
//import { Help } from "@material-ui/icons";
import AuthApi from "../services/authApi";
//import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import CalendarTodayTwoToneIcon from "@material-ui/icons/CalendarTodayTwoTone";
import QueuePlayNextIcon from "@material-ui/icons/QueuePlayNext";
import AssignmentIcon from "@material-ui/icons/Assignment";
import moment from "moment";
import Jwt from "../common/Jwt";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";
import swal from "sweetalert";
//import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
// import { useHistory } from "react-router-dom";

function Nxtrcc() {
  // const history = useHistory();
  const [dueamount, setAmountDue] = useState("0.00");
  const [stb_due_amount, setstb_due_amount] = useState("0.00");
  const [amount, setAmount] = useState("0.00");
  const [userId, setUserId] = useState("");
  const [userdate, setUserdate] = useState("");
  const [userstatus, setUseruserstatus] = useState("");
  const [dueamountcolor, setDueamountcolor] = useState("");
  const [loader, setLoader] = useState(true);
  const history = useHistory();
  // let getCompanyName = localStorage.getItem("ownerCompanyName");
  // let getCustomerId = localStorage.getItem("customerId");
  // let getCustomerName = localStorage.getItem("customerName");
  // let traknpayUrl = "";

  let newDate = new Date();
  let getYear = newDate.getFullYear().toString();
  let getMonth = newDate.getMonth().toString();
  let getDate = newDate.getDate().toString();
  let getMSec = newDate.getMilliseconds().toString();
  let combineDate = getYear + getMonth + getDate + getMSec;

  // let tranRef = "RTNP" + localStorage.getItem("customerId") + combineDate;

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    console.log(Jwt());
    const ff = Jwt();
    ff ? console.log(Jwt()) : history.push("/");
  }, []);

  const getretrack = async () => {
    // var data = new FormData();

    var config = {
      method: "post",
      url: "https://c2.mobiezy.in/api/nxt/retrack.php",
      //url: 'https://mereapneapi.nxtdigital.in/external/customer/v1/customer/login?customer_id=1052280551&initiator=MA',
      data: {
        header: localStorage.getItem("access token"),
        myData: parseInt(localStorage.getItem("sms_cust_id")),
        Merchant_Salt: localStorage.getItem("lcocode"),
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        console.log(response.data);
        toast.success("Retrack Done...", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //   const getDetails = async () => {
  //     let sourceDB = localStorage.getItem("sourceDb");

  //     let sendData = {
  //       "records": [
  //         {
  //           "owner_id": localStorage.getItem("ownerId"),
  //           "customer_number": localStorage.getItem("customerNumber")
  //         }
  //       ]
  //     };

  //     if (sourceDB === "rupayee") {

  //       await AuthApi.getRupayeePaymentDetails(sendData).then((response) => {
  //         console.log(response);
  //         setAmount(response.data.billSummary.outstanding_balance);
  //         setUserId(response.data.billSummary.user_id);
  //       });
  //     } else {
  //       await AuthApi.getMobicablePaymentDetails(sendData).then((response) => {
  //         console.log(response);
  //       });
  //     }
  //   }

  const getDetails = async () => {
    //let sourceDB = localStorage.getItem("sourceDb");

    let sendData = {
      records: [
        {
          owner_id: localStorage.getItem("ownerId"),
          customer_number: localStorage.getItem("customerNumber"),
          subscription_id: null,
        },
      ],
    };
    await AuthApi.getsubscriptiondetailsnxt(sendData)
      .then((response) => {
        console.log(response.data);
        setAmountDue(response.data.subscription[0].total_amount);
        setAmount(response.data.subscription[0].cust_due_amnt);
        setUserId(response.data.subscription[0].stb_number);
        setUserdate(response.data.subscription[0].stb_end_date);
        setUseruserstatus(response.data.subscription[0].stb_status);
        setstb_due_amount(response.data.subscription[0].cust_due_amnt);
        localStorage.setItem("stdid", response.data.subscription[0].stb_id);
        localStorage.setItem(
          "stddue",
          response.data.subscription[0].cust_due_amnt
        );
        localStorage.setItem(
          "stdnumber",
          response.data.subscription[0].stb_number
        );
        //localStorage.setItem("stddue",response.data.subscription[0].cust_due_amnt)
        //let dueamountcolor = ''
        parseFloat(response.data.subscription[0].total_amount) > 0
          ? setDueamountcolor("red")
          : setDueamountcolor("green");
        var startdate = moment("2021-09-19");
        //startdate = startdate.subtract(15, "days");
        startdate = startdate.format("DD-MM-YYYY");
        var admission = moment(moment(), "DD-MM-YYYY");
        var discharge = moment(startdate, "DD-MM-YYYY");
        let cc = admission.diff(discharge, "days");
        console.log(cc);
        setLoader(false);
      })
      .catch(function (error) {
        swal("Oops", "Something Went wrong", "error").then((value) => {
          history.push("/nxtdashboard");
        });
      });
  };
  const add_ch = async () => {
    let data = JSON.stringify({
      lcousername: localStorage.getItem("lcocode"),
      phone: localStorage.getItem("phoneNumber"),
      operator_id: parseInt(localStorage.getItem("ownerId")),
    });
    await AuthApi.getlcowallet(data)
      .then((response) => {
        console.log(response);
        if (parseFloat(response.data) > 50.0) {
          history.push("/na");
        } else {
          swal("Alert", `Insufficient Balance`, "warning");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const a = async () => {
    console.log(userdate);
    localStorage.setItem("stddue", dueamount);
    var startdate = moment(userdate);
    //startdate = startdate.subtract(15, "days");
    startdate = startdate.format("DD-MM-YYYY");
    var admission = moment(moment(), "DD-MM-YYYY");
    var discharge = moment(startdate, "DD-MM-YYYY");
    let cc = discharge.diff(admission, "days");
    console.log(cc);

    let data = JSON.stringify({
      lcousername: localStorage.getItem("lcocode"),
      phone: localStorage.getItem("phoneNumber"),
      operator_id: parseInt(localStorage.getItem("ownerId")),
    });

    await AuthApi.getlcowallet(data)
      .then((response) => {
        console.log(response);
        if (parseFloat(response.data) > 100.0) {
          if (cc > 15) {
            swal(
              "Alert",
              `You can renew your settop Box after ${cc} days`,
              "warning"
            );
          } else if (0 > cc) {
            localStorage.setItem("ec", "b");
            localStorage.setItem("statusofmodifyrenew", "1");
            history.push("/Nxtexistingpath");
          } else {
            localStorage.setItem("ec", "a");
            localStorage.setItem("statusofmodifyrenew", "0");
            history.push("/Nxtexistingpath");
          }
        } else {
          swal("Alert", `Insufficient Balance`, "warning");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const cancelstb = async () => {
    var startdate = moment(userdate);
    //startdate = startdate.subtract(15, "days");
    startdate = startdate.format("DD-MM-YYYY");
    var admission = moment(moment(), "DD-MM-YYYY");
    var discharge = moment(startdate, "DD-MM-YYYY");
    let cc = discharge.diff(admission, "days");
    if (cc < 2) {
      swal(
        "Alert",
        `You cannot cancel the channel Contact Cable Operator..`,
        "warning"
      );
    } else {
      let data1 = JSON.stringify({
        stb_id: localStorage.getItem("stdid"),
        operator_id: localStorage.getItem("ownerId"),
        cust_num: localStorage.getItem("customerNumber"),
        payment_cust_num: localStorage.getItem("payment_cust_num"),
      });
      console.log(data1);
      await AuthApi.cancelRefund(data1).then((response) => {
        /*   if(response.data.p_out_mssg_flg==="S")
         {
           swal("success",
            `Request for Cancellation Submitted`,
            "success");
            history.replace('/nxtdashboard');
         }
         else
         {
          swal(
            "Alert",
            `Something went wrong,Please Try Again....`,
            "warning"
          );
         }
        */
      });
    }
  };

  const modifyandrenewonclick = () => {
    console.log(userdate);
    var startdate = moment(userdate);
    //startdate = startdate.subtract(15, "days");
    startdate = startdate.format("DD-MM-YYYY");
    var admission = moment(moment(), "DD-MM-YYYY");
    var discharge = moment(startdate, "DD-MM-YYYY");
    let cc = discharge.diff(admission, "days");
    console.log(cc > 15000);
    if (cc > 15) {
      swal(
        "Alert",
        `You can renew your settop Box after ${cc} days`,
        "warning"
      );
    } else if (0 > cc) {
      history.push("/Nw");
      localStorage.setItem("statusofmodifyandrenew", 1);
    } else {
      history.push("/Nw");
      localStorage.setItem("statusofmodifyandrenew", 0);
    }
  };

  return (
    <React.Fragment>
      {loader ? (
        <div style={{ background: "white", height: "100vh" }}>
          <div
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: "50%",
              bottom: 0,
            }}
          >
            <center>
              {" "}
              <Loader
                type="TailSpin"
                color="#FF8C00"
                height={100}
                width={100}
              />{" "}
            </center>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <div className="row">
            <Grid container justify="flex-end" alignItems="flex-end">
              {/* <IconButton deleteIcon3 size='large' style={{ padding: '3px', paddingRight: '15px', color: '#ff8c00' }} >
            <Help HelpIcon />
          </IconButton> */}
            </Grid>
            &nbsp; &nbsp; &nbsp;
            {/* <img alt="Tv icon" src={tvlogo} width="140" height="140" />

        <div style={{ paddingTop: '25px', paddingLeft: '45px' }}>
          <h4>{getCompanyName}</h4>
          <h5>ID : {getCustomerId}</h5>
          <h5>Name : {getCustomerName}</h5>
        </div> */}
          </div>

          <div id="textbox">
            <h5 className="alignleft">
              <AssignmentIcon
                style={{ marginRight: "20px", color: "#FF8C00" }}
              />
              STB ID :
            </h5>
            <h5 className="alignright">{userId}</h5>
          </div>

          {/* <div id="textbox"><hr></hr>
  <h5 className="alignleft">Month</h5>
  <h5 className="alignright">Feb-2021</h5>
  
</div> */}

          <div id="textbox">
            <hr></hr>
            <h5 className="alignleft">
              <QueuePlayNextIcon
                style={{ marginRight: "20px", color: "#FF8C00" }}
              />
              STB Status :
            </h5>
            <h5 className="alignright" style={{ color: "blue" }}>
              {userstatus}
            </h5>
          </div>

          <div id="textbox">
            <hr></hr>
            <h5 className="alignleft">
              <CalendarTodayTwoToneIcon
                style={{ marginRight: "20px", color: "#FF8C00" }}
              />
              Expiry Date :
            </h5>
            <h5 className="alignright" style={{ color: "red" }}>
              {userdate}
            </h5>
          </div>

          <div id="textbox">
            <hr></hr>
            <h5 className="alignleft">
              <AccountBalanceWalletIcon
                style={{ marginRight: "20px", color: "#FF8C00" }}
              />
              Amount Due :
            </h5>
            <h5 className="alignright" style={{ color: dueamountcolor }}>
              {"\u20B9"} {dueamount} /-
            </h5>
          </div>

          <center>
            {" "}
            &nbsp;
            <button
              style={{
                color: "white",
                backgroundColor: "#ff8c00",
                width: "260px",
                textDecoration: "none",
                fontSize: "18px",
              }}
              type="submit"
              className="btn btn-md btn-block"
              onClick={() => a()}
            >
              Renew Existing Pack{" "}
            </button>
            &nbsp;{" "}
          </center>
          <center>
            <button
              style={{
                color: "white",
                backgroundColor: "#ff8c00",
                width: "260px",
                textDecoration: "none",
                fontSize: "18px",
                display: "none",
              }}
              type="submit"
              className="btn btn-md btn-block"
              onClick={() => modifyandrenewonclick()}
            >
              Modify & Renew{" "}
            </button>
            {localStorage.getItem("cancellation_enabled") === "Y" ? (
              <button
                style={{
                  color: "white",
                  backgroundColor: "#ff8c00",
                  width: "260px",
                  textDecoration: "none",
                  fontSize: "18px",
                }}
                type="submit"
                className="btn btn-md btn-block"
                onClick={() => cancelstb()}
              >
                Request for Cancellation{" "}
              </button>
            ) : (
              ""
            )}
            &nbsp;{" "}
          </center>
          <center>
            <button
              style={{
                color: "white",
                backgroundColor: "#ff8c00",
                width: "260px",
                textDecoration: "none",
                fontSize: "18px",
              }}
              type="submit"
              className="btn btn-md btn-block"
              onClick={() => add_ch()}
            >
              Add New Channels{" "}
            </button>
            &nbsp;{" "}
          </center>

          <center>
            <button
              style={{
                color: "white",
                backgroundColor: "#ff8c00",
                width: "260px",
                textDecoration: "none",
                fontSize: "18px",
              }}
              type="button"
              className="btn btn-md btn-block"
              onClick={() => getretrack()}
            >
              Retrack{" "}
            </button>
            &nbsp;{" "}
          </center>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default Nxtrcc;
