import React, { useState, useEffect } from "react";

//import axios from 'axios';
import AuthApi from "../services/authApi";
import AuthApi1 from "../services/authApi";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import tvlogo from "./../images/tvicon.png";
//import { Help } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Loader from "react-loader-spinner";
import swal from "sweetalert";
import { Container } from "react-floating-action-button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Check from "@material-ui/icons/Check";
import Link from "@material-ui/core/Link";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    margin: theme.spacing(2),

    borderRadius: "10px",
    padding: "20px",
  },
  tvicon: {
    width: "35%",
    marginLeft: "10px",
  },
}));

export default function NXT_CANCEL_RENEWAL() {
  const classes = useStyles();
  const [dashboarddata, setDashboarddata] = useState([]);
  const [checkstatus, setcheckstatus] = useState("SELECT ALL");

  const [selectBoxCount, setselectBoxCount] = React.useState("0");
  const [lcowallet, setlcowallet] = React.useState("");
  const [bSelected, setBSelected] = useState([]);
  const [bSelectedAmount, setBSelectedAmount] = useState([]);

  const [loader, setLoader] = React.useState(false);
  const [b, setB] = useState([]);
  const [bdef, setBdef] = useState([]);
  const history = useHistory();
  useEffect(() => {
    hello();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getlcowallet = async () => {
    let data = JSON.stringify({
      lcousername: localStorage.getItem("lcocode"),
      phone: localStorage.getItem("phoneNumber"),
      operator_id: parseInt(localStorage.getItem("ownerId")),
    });
    console.log(data);
    await AuthApi.getlcowallet(data)
      .then((response) => {
        console.log(response);
        setlcowallet(response.data);
      })
      .catch((e) => {
        console.log("Wallet Error");

        console.log(e);
      });
  };
  const hello = async () => {
    var config = {
      records: [
        {
          phone: localStorage.getItem("phoneNumber"),
          //"flag":"BC"
          flag: "BC",
        },
      ],
    };
    await AuthApi.getmpayownerlist(config)
      .then((response) => {
        console.log(response.data.owner_details[0].SUSS_FLG);
        if (response.data.owner_details[0].SUSS_FLG === "S") {
          setDashboarddata(response.data.owner_details);
          getlcowallet();
        } else {
          console.log("eee");
        }
      })
      .catch((e) => {
        setDashboarddata([]);
      });
  };
  const onCheckboxBtnClickb = (selected) => {
    console.log(selected.customer_number);
    const index = bSelected.indexOf(selected.customer_number);
    const index1 = bSelectedAmount.indexOf(selected.customer_number);
    if (index < 0) {
      bSelected.push(selected.customer_number);
    } else {
      bSelected.splice(index, 1);
    }
    setBSelected([...bSelected]);

    if (index < 0) {
      bSelectedAmount.push(selected.pack_sum);
    } else {
      bSelectedAmount.splice(index, 1);
    }
    setselectBoxCount(bSelectedAmount.length);

    setBSelectedAmount([...bSelectedAmount]);
  };
  const onClickbutton = async () => {
    if (parseInt(selectBoxCount) > 0) {
      let a = 0;
      bSelectedAmount.map((data) => {
        a = a + data;
      });
      localStorage.setItem("CUST_NUM", bSelected);
      console.log(localStorage.getItem("CUST_NUM"));
      history.push("/change");
    } else {
      /* swal("Oops", "Something Went wrong", "error").then((value) => {
        history.push("/nxtdashboard");
      });*/
      swal("Oops", "Atleast One Box Must be Selected", "error");
    }
  };
  const onClickbutton1 = async () => {
    let a = 0;
    bSelectedAmount.map((data) => {
      a = a + data;
    });
  };
  const existing = async () => {
    setLoader(true);
    let b = [];
    bSelected.map((data) => {
      b.push({ cust_num: data });
    });
    var config = {
      stb_details: b,
      operator_id: parseInt(localStorage.getItem("ownerId")),
      agent_id: "1",
      flag: "REACT_WEB",
    };
    console.log(JSON.stringify(config));
    await AuthApi1.NXTBulk_Renewal(config).then((response) => {
      //  swal("Success", "Please wait for 10 to 15 minutes to update the system", "success")
      //history.push("/nxt");

      //  BulkActivation();
      setLoader(false);
      swal(
        "Success",
        "Please allow 5-10 minutes for the channel to be activated.",
        "success"
      );
      history.push("/nxt");
    });
  };
  const BulkActivation = () => {
    let config1 = {
      phone1: parseInt(localStorage.getItem("phoneNumber")),
      lcousername: localStorage.getItem("lcocode"),
    };
    AuthApi1.NXTBulk_Activation(config1)
      .then((response) => {
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };

  const lol = () => {
    // localStorage.getItem('nxt') ? history.push("/nxtdashboard") : history.push("/dashboard")
    history.push("/nxt"); //
  };
  const selectAll = (dashboarddata) => {
    if (checkstatus === "SELECT ALL") {
      setcheckstatus("UNSELECT ALL");
    } else {
      setcheckstatus("SELECT ALL");
    }
    //  {dashboarddata.map((value) => ( )}
    dashboarddata.map((selected) => {
      console.log(selected.length);
      console.log(selected.customer_number);
      const index = bSelected.indexOf(selected.customer_number);
      const index1 = bSelectedAmount.indexOf(selected.customer_number);
      if (index < 0) {
        bSelected.push(selected.customer_number);
      } else {
        bSelected.splice(index, 1);
      }
      setBSelected([...bSelected]);

      if (index < 0) {
        bSelectedAmount.push(selected.pack_sum);
      } else {
        bSelectedAmount.splice(index, 1);
      }
      setBSelectedAmount([...bSelectedAmount]);

      setselectBoxCount(bSelectedAmount.length);
    });
  };
  return (
    <div>
      <div
        style={{
          backgroundColor: "#f5f5f5",
          height: "100vh",
          overflow: "auto",
        }}
      >
        <h3
          style={{
            color: "white",
            background: "#ff8c00",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
        >
          <IconButton
            style={{ paddingRight: "25px" }}
            color="inherit"
            onClick={() => lol()}
          >
            <ArrowBackIcon />
          </IconButton>
          InActive Boxes
        </h3>
        <br></br>
        <div className={classes.root}>
          <center>
            {" "}
            <h4>
              Your subscriptions are listed below .<br />
              Click On CheckBox to Make Bulk Reactivate Settop Box.
            </h4>
          </center>
          <div
            variant="outlined"
            style={{ float: "left", paddingLeft: "15px" }}
          >
            <h5> No Of Selected Box :{selectBoxCount}</h5>
          </div>
          <div style={{ float: "right", paddingRight: "15px" }}>
            <Button onClick={() => selectAll(dashboarddata)} variant="outlined">
              {" "}
              {checkstatus}
            </Button>
          </div>

          <Grid container>
            {dashboarddata.map((value) => (
              <Grid item xs="12" sm="6" md="4">
                <Paper className={classes.paper}>
                  <div className="row">
                    <div className={classes.tvicon}>
                      <img
                        alt="Tv icon"
                        src={tvlogo}
                        width="100"
                        height="100"
                      />
                    </div>

                    <div style={{ width: "50%" }}>
                      <h5>
                        <b>{value.customer_name}</b>
                      </h5>
                      <h5>ID:{value.customer_id}</h5>
                      <h6 style={{ color: "black", fontSize: "1.15rm" }}>
                        EXPIRY DATE:{value.PRE_END_DATE}
                      </h6>
                      <h6 style={{ color: "black", fontSize: "1.0rm" }}>
                        PACK PRICE:{value.pack_sum}
                      </h6>
                      <h6 style={{ color: "black", fontSize: "1.0rm" }}>
                        BOX NO:{value.VC_NUMBER}
                      </h6>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onClick={() => onCheckboxBtnClickb(value)}
                            checked={
                              bSelected.find(
                                (element) => element === value.customer_number
                              ) !== undefined
                                ? true
                                : false
                            } //active={cSelected.includes(6)}
                          />
                        }
                        label="Add Packs"
                      />
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
          {loader ? (
            <div>
              <center>
                {" "}
                <Loader
                  type="TailSpin"
                  color="#ff8c00"
                  height={100}
                  width={100}
                />{" "}
              </center>
            </div>
          ) : (
            <Container>
              <Fab
                style={{ color: "white", background: "#ff8c00" }}
                aria-label="add"
                tooltip="Renew"
                className={classes.margin}
                onClick={() => onClickbutton()}
              >
                NEXT
              </Fab>
            </Container>
          )}
        </div>
      </div>
    </div>
  );
}
