import React , {useState , useEffect} from 'react'
import swal from 'sweetalert';
import Loader from "react-loader-spinner";
import axios from 'axios';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

export default function Success(props) {
const [loader, setLoader] = useState(true)
const history = useHistory()
   // swal("Success", "The payment was Successfull", "success")

    useEffect(() => {
        paymentcheckingmethod()
       // renew()
       // expired()
      //addaction()
      //addactionmodifyandrenew()
      //"2021-11-29 09:18:18"
        console.log(JSON.parse(localStorage.getItem("active_pac")))
        var currentDate = moment(moment(), 'YYYY-MM-DD hh:mm:ss');
        var futureMonth = moment(currentDate).add(1, 'M');
        var futureMonthwith = futureMonth.format("YYYY-MM-DD hh:mm:ss")
      console.log(futureMonthwith.toString())
    }, [])

    const paymentcheckingmethod = async () => {
        
        
        var data =  {"tran_id": localStorage.getItem("transref")};
       
        
        var config = {
          method: 'post',
          url: 'https://91z8byyrzi.execute-api.ap-south-1.amazonaws.com/prod/mobiezyb2c-traknpay-transaction-status',
         //url: 'https://mereapneapi.nxtdigital.in/external/customer/v1/customer/login?customer_id=1052280551&initiator=MA',
         data 
         
        };
        
        axios(config)
        .then(function (response) {
          
          console.log(response.data.transactionReport.payment_status);
          console.log(data)

          if(response.data.transactionReport.payment_status == "success"){
            checkFortypeExpiry()
            
          }else{
            swal("Oops", "Something Went wrong", "error")
            history.push("/nxtdashboard")
          }
        })
        .catch(function (error) {
          console.log(error);
          swal("Oops", "Something Went wrong", "error")
        });

    }

    const checkFortypeExpiry = () =>{
      switch (localStorage.getItem("ec")) {
        case "a":
           renew()
          break;
        case "directpayment":
          //expired();
          history.push("/nxtdashboard")
          break;
          case "b":
            expired();
          break;
          case "addchannel":
            addaction();
          break;
          case "modifyandrenew":
            addactionmodifyandrenew();
          break;
        default:
          renew();
      }
      
    }


    const renew = async () => {
        
    //  // var currentDate = moment(moment(), 'DD-MM-YYYY hh:mm:ss');
    //   var currentDate = moment().format("DD-MM-YYYY hh:mm:ss")
    //  // var currentDatea = moment(moment().format("DD-MM-YYYY hh:mm:ss"))
    //   var futureMonth = moment(currentDate).add(1, 'M');
    //   var futureMonthwith = futureMonth.format("DD-MM-YYYY hh:mm:ss")

    var currentDate = moment(moment(), 'YYYY-MM-DD hh:mm:ss');
    var futureMonth = moment(currentDate).add(1, 'M');
    var futureMonthwith = futureMonth.format("YYYY-MM-DD hh:mm:ss")
      var data =  {
        "stb_id": parseFloat(localStorage.getItem("stdid")),
        "flag": "S",
        "other_details": {
          "customer_id": parseFloat(localStorage.getItem("customerNumber")),
          "expired": "0",
          "agent_id": "1",
          "duration": "1",
          "startdate": moment().format("YYYY-MM-DD hh:mm:ss").toString(),
          "flag": "S",
          "operator_id": parseFloat(localStorage.getItem("ownerId")),
          "end_date": futureMonthwith.toString(),
        },
        "pack_details": JSON.parse(localStorage.getItem("active_pac"))
      };
     
      
      var config = {
        method: 'post',
        url: 'https://ld3igodwbj.execute-api.us-west-2.amazonaws.com/prod/nxt_mobilemodifypackstraknpay_test',
       //url: 'https://mereapneapi.nxtdigital.in/external/customer/v1/customer/login?customer_id=1052280551&initiator=MA',
       data 
       
      };
      
      axios(config)
      .then(function (response) {
        
        console.log(response,data);
       // console.log(data)
       if(response.data.p_out_mssg == 'transaction successfully updated'){
         swal("Success", "Please keep your TV on for 10-15 minutes to Activate your channel ", "success")
         history.push("/nxtdashboard")
       }else{
history.push("/nxtdashboard")
       }

      
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const expired = async () => {
        
    
    var currentDate = moment(moment(), 'YYYY-MM-DD hh:mm:ss');
    var futureMonth = moment(currentDate).add(1, 'M');
    var futureMonthwith = futureMonth.format("YYYY-MM-DD hh:mm:ss")
      var data =  {
        "stb_id": parseFloat(localStorage.getItem("stdid")),
        "flag": "S",
        "other_details": {
          "customer_id": parseFloat(localStorage.getItem("customerNumber")),
          "expired": "1",
          "agent_id": "1",
          "duration": "1",
          "startdate": moment().format("YYYY-MM-DD hh:mm:ss").toString(),
          "flag": "S",
          "operator_id": parseFloat(localStorage.getItem("ownerId")),
          "end_date": futureMonthwith.toString(),
        },
        "pack_details": JSON.parse(localStorage.getItem("active_pac"))
      };
   
    
    var config = {
      method: 'post',
      url: 'https://ld3igodwbj.execute-api.us-west-2.amazonaws.com/prod/nxt_mobilemodifypackstraknpay_test',
     //url: 'https://mereapneapi.nxtdigital.in/external/customer/v1/customer/login?customer_id=1052280551&initiator=MA',
     data
     
    };
    
    axios(config)
    .then(function (response) {
      console.log(data)
      console.log(response);
     // console.log(data)
     if(response.data.p_out_mssg == 'transaction successfully updated'){

      swal("Success", "Please keep your TV on for 10-15 minutes to Activate your channel ", "success")
    history.push("/nxtdashboard")}else{
      history.push("/nxtdashboard")
             }
    
    })
    .catch(function (error) {
      console.log(error);
    });

}


const addaction = async () => {
        
    
  var currentDate = moment(moment(), 'YYYY-MM-DD hh:mm:ss');
  var futureMonth = moment(currentDate).add(1, 'M');
  var futureMonthwith = futureMonth.format("YYYY-MM-DD hh:mm:ss")
    var data =  {
      "pack_details": JSON.parse(localStorage.getItem("active_pac")),
      "stb_id":  parseFloat(localStorage.getItem("stdid")),
      "cust_num": parseFloat(localStorage.getItem("customerNumber")),
      "agent_id": "1",
    }
 
  
  var config = {
    method: 'post',
    url: 'https://ld3igodwbj.execute-api.us-west-2.amazonaws.com/prod/mobileaddchanneltraknpay_test_b2c',
   //url: 'https://mereapneapi.nxtdigital.in/external/customer/v1/customer/login?customer_id=1052280551&initiator=MA',
   data
   
  };
  
  axios(config)
  .then(function (response) {
    
    console.log(response);
   // console.log(data)
   if(response.data.p_out_mssg == 'transaction successfully updated'){
    swal("Success", "Please keep your TV on for 10-15 minutes to Activate your channel ", "success")
    history.push("/nxtdashboard")
  }else{
    history.push("/nxtdashboard")
           }
  
  })
  .catch(function (error) {
    console.log(error);
  });

}

const addactionmodifyandrenew = async () => {
        
    
  var currentDate = moment(moment(), 'YYYY-MM-DD hh:mm:ss');
  var futureMonth = moment(currentDate).add(1, 'M');
  var futureMonthwith = futureMonth.format("YYYY-MM-DD hh:mm:ss")
    var data =  {
      "others_details": {
        "operatorId": parseFloat(localStorage.getItem("ownerId")),
        "brds_stb_status":  localStorage.getItem("statusofmodifyandrenew"),
        "customer_number": parseFloat(localStorage.getItem("customerNumber")),
        "agent_id": "1",
        "pack_base_amount": parseFloat(localStorage.getItem("nxtrenewandmodifytotalbase")),
        "pack_tax_amount": parseFloat(localStorage.getItem("nxtrenewandmodifytotaltax")),
        "pack_total_amount":parseFloat(localStorage.getItem("nxtrenewandmodifytotalammount")),
        "pre_start_date": moment().format("YYYY-MM-DD hh:mm:ss").toString(),
        "pre_bill_date":
        futureMonthwith.toString(),
        "pre_end_date":futureMonthwith.toString(),
        "collect_datetime":
       futureMonthwith.toString()
      },
      "stb_details": {
        "stb_id": parseFloat(localStorage.getItem("stdid")),
        "service_status": "Active",
        "lco_code": "1001506184",
        "agent_id": "1",
        "stb_number": localStorage.getItem("stdnumber")
      },
      "tran_details": {
        "total_base_amount": parseFloat(localStorage.getItem("nxtrenewandmodifytotalbase")),
        "total_previous_due_amount": "0",
        "total_payable_amount": parseFloat(localStorage.getItem("nxtrenewandmodifytotalammount")),
        "collected_amount": 0,
        "mode_of_payment": "1",
        "collection_remarks": "",
        "transaction_type": "collection",
        "tran_id": ""
      },
      "packdetails": JSON.parse(localStorage.getItem("active_pac"))
    }
 
  
  var config = {
    method: 'post',
    url: 'https://ld3igodwbj.execute-api.us-west-2.amazonaws.com/prod/nxt_managerenewalprocesswithchangetraknpay_b2c_prc',
   //url: 'https://mereapneapi.nxtdigital.in/external/customer/v1/customer/login?customer_id=1052280551&initiator=MA',
   data
   
  };
  
  axios(config)
  .then(function (response) {
    
    console.log(response,data);
   // console.log(data)
   if(response.data.p_out_mssg == 'transaction successfully updated'){
    swal("Success", "Please keep your TV on for 10-15 minutes to Activate your channel ", "success")
    history.push("/nxtdashboard")
  }else{
    history.push("/nxtdashboard")
           }
  
  })
  .catch(function (error) {
    console.log(error);
  });

}
    return (
        <div style={{background:'white',height:'100vh'}}>
            <div style={{position:'absolute',left:0,right:0,top:'50%',bottom:0}}>
            <center>
            {loader ?  <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        
      /> : null}
      </center>
      </div>
        </div>
    )
}
