import React, { useState,useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Avatar from '@material-ui/core/Avatar';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Complaint from './../images/notification.png';
import AccordionDetails from '@material-ui/core/AccordionDetails';

//import { makeStyles } from '@material-ui/core/styles';
//import Accordion from '@material-ui/core/Accordion';
//import AccordionDetails from '@material-ui/core/AccordionDetails';
//import AccordionSummary from '@material-ui/core/AccordionSummary';
//import Typography from '@material-ui/core/Typography';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import Complaint from './../images/notification.png';
//import Avatar from '@material-ui/core/Avatar';
//import { Container, Button } from 'react-floating-action-button'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Link from '@material-ui/core/Link';
import Loader from 'react-loader-spinner';
import AuthApi from '../services/authApi';
//import Loader from 'react-loader-spinner';
//import { IconButton } from '@material-ui/core';
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom";



//import Avatar from '@material-ui/core/Avatar';
//import Checkbox from "@material-ui/core/Checkbox";
//import FormControlLabel from "@material-ui/core/FormControlLabel";
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import axios from 'axios';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const useStyles = makeStyles((theme) => ({
  root: {
      display: 'flex',
      background: 'white'
  },
 
  
  content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
  },
  container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(4),
  },
  paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
  },
  fixedHeight: {
      height: 220,
  },
  appBarSpacer:{
    marginTop: theme.spacing(1)
  }
}));

const useStyle = makeStyles((theme) => ({
  root: {
    height: '100vh',
    overflow: 'auto',
    background: 'white',
    width: '100%',
  },
  margin: {
    margin: theme.spacing(1),
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: '#ff7700'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));
export default function Nxtaddchannel() {
    const [value, setValue] = React.useState('one');
    const classes = useStyles();
    const [cSelected, setCSelected] = useState([]);
    const [aSelected, setASelected] = useState([]);
    const [bSelected, setBSelected] = useState([]);
    const [a, setA] = useState([]);
    const [b, setB] = useState([]);
    const [c, setC] = useState([]);
    const [adef, setAdef] = useState([]);
    const [bdef, setBdef] = useState([]);
    const [cdef, setCdef] = useState([]);
    const [category_check_list, setCategoryCheckList] = useState([]);
    const history = useHistory()
    const [loader, setLoader] = useState(true)
    useEffect(() => {
      getItems();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const getItems = () => {
      var config = {
        method: 'post',
        url: 'https://91z8byyrzi.execute-api.ap-south-1.amazonaws.com/prod/rupayee-getb2cpacklist-new',
       
        data : {"records":[{"operator_id":localStorage.getItem("ownerId")}]}
      };
      
      axios(config)
      .then(function (response) {
        setC(response.data.mso_bouquet);
        setB(response.data.pay_channel)
        setA(response.data.base_pack_fta)
        setCdef(response.data.mso_bouquet);
        setBdef(response.data.pay_channel)
        setAdef(response.data.base_pack_fta)
       console.log(response.data.mso_bouquet)
       setLoader(false)
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    const categoryCheckboxFilterHandler = e => {
        if (e.target.checked == true){
            setCategoryCheckList([...category_check_list, e.target.value]);
        }else{
            let check_list = [];
            category_check_list.map(check => {
                if (Number(check) != Number(e.target.value)){
                    check_list.push(Number(check));
                }
            });
            setCategoryCheckList(check_list);
        }
    };
    var checked_category_id_list = category_check_list.toString();

    
//const [rSelected, setRSelected] = useState(null);

const onCheckboxBtnClick = (selected) => {
  const index = cSelected.indexOf(selected);
  if (index < 0) {
    cSelected.push(selected);
  } else {
    cSelected.splice(index, 1);
  }
  setCSelected([...cSelected]);
}

const onCheckboxBtnClickb = (selected) => {
  const index = bSelected.indexOf(selected);
  if (index < 0) {
    bSelected.push(selected);
  } else {
    bSelected.splice(index, 1);
  }
  setBSelected([...bSelected]);
}

const onCheckboxBtnClicka = (selected) => {
  const index = aSelected.indexOf(selected);
  if (index < 0) {
    aSelected.push(selected);
  } else {
    aSelected.splice(index, 1);
  }
  setASelected([...aSelected]);
}
const tr = () =>{
  localStorage.setItem("naa",String(aSelected))
  localStorage.setItem("nab",String(bSelected))
  localStorage.setItem("nac",String(cSelected))
  console.log(String(aSelected));
  history.push("/nwa")
}
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleChangeb = (e) => {
    
     let x = bdef.filter(country => {
        return country.pack_name.toLowerCase().includes(e.target.value.toLowerCase())
       })
       console.log(x)
      setB(x)
    };

    const handleChangec = (e) => {
     
      let x = cdef.filter(country => {
         return country.pack_name.toLowerCase().includes(e.target.value.toLowerCase())
        })
        console.log(x)
       setC(x)
     };
    return (
        <div style={{ background: 'white' }}>
            
            <h3 style={{ color: 'white', background: '#ff8c00', paddingTop: '8px', paddingBottom: '8px',width: '100%' }}>
        <IconButton style={{ paddingRight: '25px' }} onClick={() =>history.push('/nxtrenewalchannel')} color="inherit" >
          <ArrowBackIcon />
        </IconButton>Add Channel</h3>
     
      <center>
        
        <h4> Add your new Channel</h4>
         </center>
  <div className={classes.root} >
 
              {/* <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List>{mainListItems}</List>
                <Divider />
                <List>{secondaryListItems}</List>
            </Drawer> */}
            
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                {loader ? <div style={{position:'absolute',left:0,right:0,top:'50%',bottom:0}}> <center> <Loader
        type="TailSpin"
        color='#ff8c00'
        height={100}
        width={100}
        
      /></center> </div>:
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={4}>
                       
                        {/* <Grid item xs={12} md={8} lg={8}>
                            <Paper className={fixedHeightPaper}>
                                <STBDetail />
                            </Paper>
                        </Grid>
                        
                        <Grid item xs={12} md={4} lg={4}>
                            <Paper className={classes.paper}>
                                <RecentComplaint />
                            </Paper>
                        </Grid> */}
                       
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper className={classes.paper}>
                            <Tabs>
    <TabList>
      
      <Tab style={{width:'50%'}}>Pay Channels</Tab>
      <Tab style={{width:'50%'}}>Bouquet Packs</Tab>
      
    </TabList>

    
    <TabPanel >
    <div style={{height:'60vh',overflow:'auto',marginBottom:'15px'}}>
    <FormGroup>
    <FormLabel component="legend">  <form action="/action_page.php">
      <input type="text" onChange={handleChangeb} style={{border:'none'}} placeholder="Search.." name="search"/>
      <button type="button"   style={{border:'none',borderRadius:'10px'}}><SearchIcon/></button>
    </form></FormLabel>
    {b.map((number) =>
   <FormControlLabel
   control={
     <Checkbox
     onClick={() => onCheckboxBtnClickb(number.pack_name)} 
     checked={bSelected.find(element => element == number.pack_name) !== undefined ? true : false }//active={cSelected.includes(6)}
     />
   }
   label={String((number.pack_name == undefined ? '---' : number.pack_name)  + '--'+ ' ' + `${"\u20B9"}` +  (number.total_amount == undefined ? '---' : number.total_amount) + '/-')}
 />
  )}
  </FormGroup>
  </div>
    </TabPanel>
    <TabPanel >
    <div style={{height:'60vh',overflow:'auto',marginBottom:'15px'}}>
    <FormGroup>
    <FormLabel component="legend">  <form action="/action_page.php">
      <input type="text" onChange={handleChangec} style={{border:'none'}} placeholder="Search.." name="search"/>
      <button type="button"   style={{border:'none',borderRadius:'10px'}}><SearchIcon/></button>
    </form></FormLabel>
    {c.map((number) =>
   <FormControlLabel
   control={
     <Checkbox
     onClick={() => onCheckboxBtnClicka(number.pack_name)}
     checked={aSelected.find(element => element == number.pack_name) !== undefined ? true : false } //active={cSelected.includes(6)}
     />
   }
   label={String((number.pack_name == undefined ? '---' : number.pack_name)  + '--'+ ' ' + `${"\u20B9"}` +  (number.total_amount == undefined ? '---' : number.total_amount) + '/-')}
 />
  )}
  </FormGroup>
  </div>
    </TabPanel>
    <center>
    <button style={{color: "white", backgroundColor: '#ff8c00', width: '200px', textDecoration: 'none' }}
          type="submit" className="btn btn-lg btn-block" onClick={() =>tr()} >Add Channel </button></center>
  </Tabs>
                            </Paper>
                        </Grid>
                        {/* <Grid item xs={12} md={3} lg={3}>
                            <Paper className={classes.paper}>
                                <Adds />
                            </Paper>
                        </Grid> */}
                    </Grid>
                    {/* <Box pt={4}>
                        <Copyright />
                    </Box> */}
                </Container>
}
            </main>
            
        </div>

    
        </div>





    )
}


