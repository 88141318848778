import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import '../subcriptionDetail/addComplaint.css'
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import Loader from "react-loader-spinner";

import swal from 'sweetalert';
import AuthApi1 from "../services/authApi";
toast.configure()


const useStyles = makeStyles((theme) => ({
  root: {

    overflow: 'auto',
    background: 'white',
    height:'100vh',
    width: '100%',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 280,
    },

  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 280,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


export default function Nxtmodifiedpackdetails() {
    const classes = useStyles();
  const history = useHistory();
  const [a, setA] = useState([]);
    const [b, setB] = useState([]);
    const [c, setC] = useState([]);
    const [mso, setMso] = useState([]);
    const [pc, setPc] = useState([]);
    const [bft, setBft] = useState([]);
    const [active, setActive] = useState([]);
    const [totalamount , setTotalamount] = useState(0)
    const [totalamountewithtax , setTotalamountwithtax] = useState(0)
    const [totalamountwithbase , setTotalamountwithbase] = useState(0)
    const [loader, setLoader] = useState(true)
  useEffect(() => {
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getItems = () => {
    var config = {
      method: 'post',
      url: 'https://91z8byyrzi.execute-api.ap-south-1.amazonaws.com/prod/rupayee-getb2cpacklist-new',
     
      data : {"records":[{"operator_id":localStorage.getItem("ownerId")}]}
    };
    
    axios(config)
    .then(function (response) {
      setC(response.data.mso_bouquet);
      setB(response.data.pay_channel)
      setA(response.data.base_pack_fta)
      const obj = localStorage.getItem("naa")
      const obj1 = localStorage.getItem("nab")
      const obj2 = localStorage.getItem("nac")
      const myArr = obj.split(",")//base pack
      const myArr1 = obj1.split(",")//mso
      const myArr2 = obj2.split(",")//paychanels
      
      var x = response.data.mso_bouquet
      var x1 = response.data.pay_channel
    
      var doubled = myArr.map((number) => x1.find(element => element.pack_id == number));
     
      setMso(doubled)
      
      var doubled1 = myArr1.map((number) => x.find(element => element.pack_id == number));
     
      setPc(doubled1)
      var x2 = response.data.base_pack_fta
      var doubled2 = myArr2.map((number) => x2.find(element => element.pack_id == number));
   
      setBft(doubled2)
     
      const comps = doubled.map((value) => parseFloat(value == undefined ? 0 : value.total_amount))
     
        let xx = comps.reduce((a, b) => a + b, 0)// base price
 //this vairable is created for the request in Success.js 
 const compstax = doubled.map((value) => parseFloat(value == undefined ? 0 : value.total_tax))

     

      const compsbase = doubled.map((value) => parseFloat(value == undefined ? 0 : value.base_price))
 
        let xxtax = compstax.reduce((a, b) => a + b, 0)

        let xxbase = compsbase.reduce((a, b) => a + b, 0)

        //--------------------------------------------------------
        const compss = doubled1.map((value) => parseFloat(value == undefined ? 0 : value.total_amount))

    
        let xx1 = compss.reduce((a, b) => a + b, 0)//mso price
        //this vairable is created for the request in Success.js 
        const compssTax = doubled1.map((value) => parseFloat(value == undefined ? 0 : value.total_tax))

       
          
        const compssBase = doubled1.map((value) => parseFloat(value == undefined ? 0 : value.base_price))

      
          let xx1Tax = compssTax.reduce((a, b) => a + b, 0)
          let xx1Base = compssBase.reduce((a, b) => a + b, 0)
        //----------------------------------------------------------

        const compses = doubled2.map((value) => parseFloat(value == undefined ? 0 : value.total_amount))

     
        let xx2 = compses.reduce((a, b) => a + b, 0)// pay channel
        //this vairable is created for the request in Success.js 
        const compsestax = doubled2.map((value) => parseFloat(value == undefined ? 0 : value.total_tax))
        const compsesbase = doubled2.map((value) => parseFloat(value == undefined ? 0 : value.base_price))
         
         
         setTotalamount(  parseFloat(xx2)+parseFloat(xx1)+parseFloat(xx))
        
//==================================================
        setLoader(false) ;   
        
    })

    
     .catch(function (error) {
       console.log(error);
     });
  }

  const undifinedcheck =() =>{
    if(bft == undefined){
      var x =[...mso,...pc,...active]
      console.log(x)
    }else if(mso == undefined){
      var x =[...bft,...pc,...active]
    }else if(pc == undefined){
      var x =[...bft,...mso,...active]

    }else if(active == undefined){
      var x =[...bft,...mso,...pc]
    }else{
      var x =[...bft,...mso,...pc,...active]
    }
    
    localStorage.setItem("active_pac",JSON.stringify(x))
  }

  const nxtpage =async() =>{
    let config=localStorage.getItem("jsonRequest")
    console.log(config);
    setLoader(true);
    await AuthApi1.NXTBulk_Change_Pack(config).then((response) => {

      console.log(response);
   
     
      if(response.data.p_out_mssg_flg==="S"){
        setLoader(false);
   
       swal("Success", "Please wait for 10 to 15 minutes to update the system", "success")
         history.push("/nxt");
      
      }
      else
      {
        setLoader(false);
        swal(response.data.p_out_mssg)
 
      }
       
     }).catch((e)=>{
      setLoader(false);
       console.log(e);
     }) 
  
  }
    return (
        <div style={{background:'white',height:'100vh'}}>
           
            {loader ?  <div style={{position:'absolute',left:0,right:0,top:'50%',bottom:0,background:'white'}}>
            <center> <Loader
        type="TailSpin"
        color="orange"
        height={100}
        width={100}
        
      /> </center>
      </div>: 
          <div style={{ backgroundColor: '#f5f5f5', height: "100%" }} className={classes.root}>
      <h3 style={{ color: 'white', background: '#ff8c00', paddingTop: '8px', paddingBottom: '8px' }}>
        <IconButton style={{ paddingRight: '25px' }} color="inherit" onClick={() => history.push('/change')}>
          <ArrowBackIcon />
        </IconButton>Subscription</h3>
      <br></br>
      <center>
          Selected Subscription
          &nbsp;
      <div
        
      >




<table>
  <tbody>
  <tr>
    <th style={{width:'30vw'}}>Pack Name</th>
    <th style={{width:'30vw'}}>Pack Type</th>
    <th style={{width:'30vw'}}>Price</th>
  </tr>
 
  {bft.map((value) =>
<tr style={value == undefined ? {display:'none'} : null}>
    <td>{value == undefined ? null : value.pack_name}</td>
    <td>{value == undefined ? null : value.pack_type}</td>
    <td> {value == undefined ? null :  `${"\u20B9"}${value.total_amount}`} </td>
  </tr>
  
)}
 {mso.map((value) =>
<tr style={value == undefined ? {display:'none'} : null}>
    <td>{value == undefined ? null : value.pack_name }</td>
    <td>{value == undefined ? null : value.pack_type }</td>
    <td> {value == undefined ? null :  `${"\u20B9"}${value.total_amount}` } </td>
  </tr>
)}
 {pc.map((value) =>
<tr style={value == undefined ? {display:'none'} : null}>
    <td>{value == undefined ? null : value.pack_name}</td>
    <td>{value == undefined ? null : value.pack_type}</td>
    <td> {value == undefined ? null : `${"\u20B9"}${value.total_amount}`} </td>
  </tr>
)}
 {active.map((value) =>
<tr style={value == undefined ? {display:'none'} : null}>
    <td>{value == undefined ? null : value.pack_name}</td>
    <td>{value == undefined ? null : value.pack_type}</td>
    <td> {value == undefined ? null :  `${"\u20B9"}${value.total_amount}`} </td>
  </tr>
)}
</tbody>

</table>
{/* <div>
<Link href="/addComplaint" onClick={'/addComplaint'}>
  <Fab  aria-label="add" className={classes.margin}>
  
    <AddIcon />
  </Fab></Link>
</div> */}


{/* <Container>
  <Link href="/addComplaint">
    <Fab aria-label="add" tooltip="Add Complaint" className={classes.margin}>

      <AddIcon />
    </Fab></Link>
</Container> */}
</div>

<div style={{ marginTop:'20px' , marginBottom:'20px' , color: 'blue'}} >Total Amount (Incl GST)  : <span style={{ marginTop:'20px' , marginBottom:'20px' , color: 'red'}} > {"\u20B9"} {totalamount} /-</span></div>

&nbsp;


<button style={{color: "white", backgroundColor: '#ff8c00', width: '260px', textDecoration: 'none' }}
          type="button" onClick={()=>nxtpage()} className="btn btn-lg btn-block" >Submit </button>
&nbsp;
</center>

    </div> 
    
}
  

        </div>
    )
}

