import moment from "moment";

export default function Jwt() {
  //const history = useHistory();
  var CurrentHour = moment(localStorage.getItem("currentdate")).format("HH");
  var Currentm = moment(localStorage.getItem("currentdate")).format("mm");
  console.log(CurrentHour);
  console.log(moment(localStorage.getItem("currentdate")).format("mm"));
  // var a = moment('2016-06-06T21:03:55');//now
  var b = moment().format("mm");
  var c = moment().format("HH");
  //var d = moment(CurrentDate)
  console.log(localStorage.getItem("access token"));

  if (
    parseInt(CurrentHour) === parseInt(c) &&
    parseInt(Currentm) - parseInt(b) < 50
  ) {
    return true;
  } else {
    return false;
  }

  //.log(CurrentDate.diff(b, 'minutes')%60)
}
