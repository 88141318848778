import React, { useState, useEffect } from "react";
import "./STBpayment.css";
import tvlogo from "./../images/tvicon.png";
import { Grid, IconButton } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import AuthApi from "../services/authApi";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
// import { useHistory } from "react-router-dom";

function Subcription() {
  // const history = useHistory();
  const [amount, setAmount] = useState("0.00");
  const [userId, setUserId] = useState("");
  let getCompanyName = localStorage.getItem("ownerCompanyName");
  let getCustomerId = localStorage.getItem("customerId");
  let getCustomerName = localStorage.getItem("customerName");
  let traknpayUrl = "";

  let newDate = new Date();
  let getYear = newDate.getFullYear().toString();
  let getMonth = newDate.getMonth().toString();
  let getDate = newDate.getDate().toString();
  let getMSec = newDate.getMilliseconds().toString();
  let combineDate = getYear + getMonth + getDate + getMSec;

  let tranRef = "RTNP" + localStorage.getItem("customerId") + combineDate;

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDetails = async () => {
    let sourceDB = localStorage.getItem("sourceDb");

    let sendData = {
      records: [
        {
          owner_id: localStorage.getItem("ownerId"),
          customer_number: localStorage.getItem("customerNumber"),
        },
      ],
    };

    if (sourceDB === "rupayee") {
      await AuthApi.getRupayeePaymentDetails(sendData).then((response) => {
        console.log(response);
        setAmount(response.data.billSummary.outstanding_balance);
        setUserId(response.data.billSummary.user_id);
      });
    } else {
      await AuthApi.getMobicablePaymentDetails(sendData).then((response) => {
        console.log(response);
      });
    }
  };

  const generateTraknpay = async () => {
    let sendToTraknpay = {
      address_line_1: localStorage.getItem("traknpaySaltKey"),
      amount: 5.0,
      api_key: localStorage.getItem("traknpayApiKey"),
      city: "Bangalore",
      country: "India",
      currency: "INR",
      description: "online payment",
      email: "kifijow167@maillei.com",
      name: localStorage.getItem("customerName"),
      order_id: tranRef,
      phone: localStorage.getItem("phoneNumber"),
      return_url:
        "https://www.mobicollector.com/traknpay/response_pageReact.php",
      return_url_failure:
        "https://www.mobicollector.com/traknpay/response_pageReact.php",
      state: "Karnataka",
      udf1: localStorage.getItem("customerNumber"),
      udf2: localStorage.getItem("ownerId"),
      udf3: localStorage.getItem("sourceDb"),
      udf4: userId,
      udf5: localStorage.getItem("customerId"),
      zip_code: "560001",
    };

    await AuthApi.getTraknpayUrlRequest(sendToTraknpay).then((response) => {
      console.log(response);
      if (response.data.data != null) {
        traknpayUrl = response.data.data.url;
        console.log(traknpayUrl);
        initTransaction();
        window.open(traknpayUrl, "_self");
      }
    });
  };

  const initTransaction = async () => {
    let sendData = {
      cust_id: localStorage.getItem("customerNumber"),
      owner_id: localStorage.getItem("ownerId"),
      amount: amount,
      tran_id: tranRef,
      //"merchant_id":"",
      pay_partner: "traknpay",
      pay_req: "{}",
      pay_stat1: "initiated",
      comments: "",
      flag: "I",
    };

    await AuthApi.sendTransactionDataToDb(sendData).then((response) => {
      console.log(response);
    });
  };

  const history = useHistory();

  return (
    <React.Fragment>
      <div className="row">
        <Grid container justify="flex-start" alignItems="flex-start">
          <IconButton
            style={{ paddingRight: "30px" }}
            color="inherit"
            onClick={() => history.push("/dashboard")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid container justify="flex-end" alignItems="flex-end">
          <IconButton
            deleteIcon3
            size="large"
            style={{ padding: "3px", paddingRight: "15px", color: "#ff8c00" }}
          >
            <Help HelpIcon />
          </IconButton>
        </Grid>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <img alt="Tv icon" src={tvlogo} width="140" height="140" />
        <div style={{ paddingTop: "25px", paddingLeft: "45px" }}>
          <h4>{getCompanyName}</h4>
          <h5>ID : {getCustomerId}</h5>
          <h5>Name : {getCustomerName}</h5> 
        </div>
      </div>
      <h4 style={{ color: "#ff8c00", paddingLeft: "5px", paddingTop: "5px" }}>
        <hr></hr>Billing Details({"\u20B9"}) <hr></hr>
      </h4>
      <div id="textbox">
        <h5 className="alignleft">Status</h5>
        <h5 className="alignright" style={{ color: "green" }}>
          Active
        </h5>
      </div>

      {/* <div id="textbox"><hr></hr>
  <h5 className="alignleft">Month</h5>
  <h5 className="alignright">Feb-2021</h5>
  
</div> */}

      <div id="textbox">
        <hr></hr>
        <h5 className="alignleft">Amount Due</h5>
        <h5 className="alignright" style={{ color: "red" }}>
          {"\u20B9"} {amount} /-
        </h5>
      </div>

      <center>
        <hr></hr> &nbsp;{" "}
        <h4>
          Total Payable Amount : {"\u20B9"} {amount} /-
        </h4>
        <button
          style={{
            color: "white",
            backgroundColor: "#ff8c00",
            width: "200px",
            textDecoration: "none",
          }}
          type="submit"
          className="btn btn-lg btn-block"
          onClick={() => generateTraknpay()}
        >
          Proceed to Pay{" "}
        </button>
        &nbsp;{" "}
      </center>
    </React.Fragment>
  );
}

export default Subcription;
